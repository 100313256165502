import { Link } from '~/src/components/Link';
import { Code } from "@uc-common/code";
import { uploadcareUploader, uploadFile, vanillaJSUpload } from "~/src/learning/code-examples/code-examples";
import * as React from 'react';
export default {
  Link,
  Code,
  uploadcareUploader,
  uploadFile,
  vanillaJSUpload,
  React
};