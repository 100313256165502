export const isDefined = (value) => value !== undefined && value != null;
export const pluralize = (count, singleForm, pluralForm, strict) => {
  const form = count === 1 ? singleForm : pluralForm;

  if (strict) {
    return form;
  }

  return `${count} ${form}`;
};

export const toSnakeCase = (string) => {
  return string
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .replace(/\s+/g, '_')
    .toLowerCase();
};
