import { useWindowWidth } from '@react-hook/window-size/throttled';
import { useTheme } from 'styled-components';

export const BreakpointEnum = {
  XS: 0,
  MD: 1,
  LG: 2,
  XL: 3,
};

export const useCurrentBreakpoint = ({ initialWindowWidth, fps = 5 } = {}) => {
  const { breakpoints } = useTheme();
  const intBreakpoints = breakpoints.map((str) => parseInt(str, 10));

  const initialWidth =
    typeof initialWindowWidth !== 'undefined'
      ? initialWindowWidth
      : intBreakpoints[intBreakpoints.length - 1];

  const windowWidth = useWindowWidth(initialWidth, { fps });

  const nearest = [...intBreakpoints].reverse().find((px) => windowWidth >= px);
  const idx = intBreakpoints.includes(nearest) ? intBreakpoints.indexOf(nearest) + 1 : 0;

  return idx;
};
