import React, { useState } from 'react';
import urlJoin from 'url-join';
import InputSelect from '@uc-common/input-select';
import Image from '@uc-common/image';

import {
  CDNComparisonContainer,
  CDNComparisonSelectors,
  CDNComparisonSelectorLabel,
  CDNTransformationExampleCDN,
  CDNTransformationExample,
  CDNComparisonContent,
  CDNTransformationExampleText,
} from './styles/CDNComparison.styles';

import { CDNS_TO_COMPARE, CDN_TRANSFORMATIONS } from './data';
import { getOptions } from './utils';

export const CDNComparison = () => {
  const [selectedCDN, setSelectedCDN] = useState({});
  const [selectedTransformation, setSelectedTransformation] = useState(null);

  const availableTransformations = CDN_TRANSFORMATIONS.get(selectedCDN.value);
  const transformationsOptions = getOptions(availableTransformations);
  const imageExampleSrc = selectedCDN.exampleImage?.replace(
    '<>',
    selectedTransformation?.operation
  );

  const handleCDNChange = (cdn) => {
    selectedCDN.value !== cdn && setSelectedTransformation(null);
    setSelectedCDN(CDNS_TO_COMPARE.find(({ value }) => value === cdn));
  };

  const handleTransformationChange = (transformation) =>
    setSelectedTransformation(
      availableTransformations.find(({ value }) => value === transformation)
    );

  return (
    <CDNComparisonContainer>
      <CDNComparisonSelectors>
        <div>
          <CDNComparisonSelectorLabel>CDN:</CDNComparisonSelectorLabel>
          <InputSelect
            placeholder="Select CDN"
            options={getOptions(CDNS_TO_COMPARE)}
            onChange={handleCDNChange}
          />
        </div>

        <div>
          <CDNComparisonSelectorLabel>Transformations:</CDNComparisonSelectorLabel>
          <InputSelect
            value={selectedTransformation?.value ?? ''}
            placeholder="Select transformation"
            options={transformationsOptions}
            onChange={handleTransformationChange}
            isDisabled={transformationsOptions.length === 0}
            isSearchable
          />
        </div>
      </CDNComparisonSelectors>
      {selectedTransformation && (
        <CDNComparisonContent>
          <CDNTransformationExample>
            <CDNTransformationExampleCDN>{selectedCDN.label}</CDNTransformationExampleCDN>
            <CDNTransformationExampleText>
              {selectedTransformation.operation}/
            </CDNTransformationExampleText>
            <img src={imageExampleSrc} alt={`${selectedCDN.label} example`} />
          </CDNTransformationExample>
          <CDNTransformationExample>
            <CDNTransformationExampleCDN>Uploadcare</CDNTransformationExampleCDN>
            <CDNTransformationExampleText>
              {selectedTransformation.ucOperation}
            </CDNTransformationExampleText>
            <Image
              src={urlJoin(
                'https://ucarecdn.com/',
                selectedCDN.ucExampleImageUUID,
                selectedTransformation.ucOperation
              )}
              alt="Uploadcare example"
            />
          </CDNTransformationExample>
        </CDNComparisonContent>
      )}
    </CDNComparisonContainer>
  );
};
