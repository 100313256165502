import { useCallback, useState } from 'react';

const isSSR = typeof window === 'undefined';

export const setCookie = (name, value, options = {}) => {
  if (isSSR) return;

  options = {
    path: '/',
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;

  // eslint-disable-next-line guard-for-in
  for (const optionKey in options) {
    updatedCookie += `; ${optionKey}`;
    const optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += `=${optionValue}`;
    }
  }

  document.cookie = updatedCookie;
};

export const getCookie = (name) => {
  if (isSSR) return;

  const matches = document.cookie.match(
    new RegExp(
      // eslint-disable-next-line
      '(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)'
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const removeCookie = (name) => {
  setCookie(name, '', {
    'max-age': -1,
  });
};

export const useCookie = (name) => {
  const [cookies, setCookieState] = useState(getCookie(name));

  const setCookieHook = useCallback(
    (...params) => {
      setCookie(...params);
      setCookieState(params[1]);
    },
    [setCookieState]
  );

  const removeCookieHook = useCallback(
    (...params) => {
      removeCookie(...params);
      setCookieState(null);
    },
    [setCookieState]
  );

  return [cookies, setCookieHook, removeCookieHook];
};
