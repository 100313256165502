import { Link } from '~/src/components/Link';
import { Code } from "@uc-common/code";
import { rotateExample, rotateExample2 } from "~/src/learning/code-examples/code-examples";
import * as React from 'react';
export default {
  Link,
  Code,
  rotateExample,
  rotateExample2,
  React
};