/* Vendors */
import React from 'react';
import styled from 'styled-components';

/* Styles */

const StyledInlineCode = styled.code`
  font: 400 14px var(--font-mono);
  position: relative;
  bottom: 3px;
  display: inline-flex;
  line-height: 1.5;
  background-color: #f0f1f1;
  color: #39424a;
  border-radius: 5px;
  padding: 1px 6px;
  max-width: 100%;
  overflow: auto;
  border: 1px solid rgba(2, 25, 30, 0.03);
`;

/* Heading */
export const InlineCode = ({ children }) => <StyledInlineCode>{children}</StyledInlineCode>;

/* default props */
InlineCode.defaultProps = {};
