import React, { cloneElement } from 'react';
import styled, { css } from 'styled-components';

export const H1 = styled.h1`
  font-family: var(--font-headings);
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 32px;
  letter-spacing: 0.004px;

  margin: 0 0 30px 0;
`;

export const Text = styled.p`
  max-width: unset;

  font-family: var(--font-sans);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 0.004px;

  margin: 0;
  padding: 0;

  color: #000000;
`;

export const Card = styled.div`
  border: 1px solid #eaeaea;
  border-radius: 15px;
`;

export const H2 = styled.h2`
  font-family: var(--font-headings);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  letter-spacing: 0.004px;

  margin: 0 0 25px;
  padding: 0;

  display: flex;
  align-items: center;

  color: #000000;
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const InfoList = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  font-family: var(--font-mono);
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 36px;

  color: #444444;

  letter-spacing: 0.004px;
`;

export const ItemLabel = styled.span`
  color: #bbbbbb;
`;

export const BigImage = styled.img`
  display: block;
  max-width: 100%;
  transform: scale(1);
  opacity: 1;

  transition:
    opacity 0.1s ease-in-out,
    transform 0.3s ease-in-out;

  ${({ isLoading }) =>
    isLoading &&
    css`
      opacity: 0.1;
      transform: scale(0.95);
    `}
`;

export const Center = styled.div`
  margin: 30px 0 0;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Url = styled.div`
  font-family: var(--font-mono);
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 29px;
  letter-spacing: 0.004px;

  margin: 0 0 25px;

  display: flex;
  flex-flow: row wrap;
  align-items: center;

  color: #444444;
`;

export const UrlPart = styled.span`
  color: #ffffff;
  box-sizing: border-box;
  border-radius: 5px;

  padding: 5px 12px;
  margin: 2px 0px;
  line-height: 24px;
  display: inline-block;

  cursor: default;

  background-color: ${({ active }) => (active ? '#000000' : '#222222')};
`;

export const Link = styled.a`
  font-family: var(--font-mono);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.004px;
  text-decoration: none;
  word-break: break-all;

  color: #444444;

  &:hover {
    color: #b5b5b5;
  }

  ${({ isAnyActive }) =>
    isAnyActive &&
    css`
      color: #b5b5b5;
    `}
`;

export const LinkHighlighter = styled.span`
  padding: 4px 2px;
  background-color: ${({ active }) => (active ? '#FEF5DA' : 'transparent')};
  color: ${({ active }) => (active ? '#222222' : 'inherit')};
`;

export const Circle = styled.span`
  background-color: ${({ color }) => color};

  display: inline-block;

  width: 16px;
  height: 16px;

  margin: 0 10px 0 0;
  border-radius: 8px;
`;

const Popover = styled.div`
  position: absolute;
  bottom: 46px;

  width: 250px;

  background-color: #eeeeee;
  border-radius: 10px;

  font-family: var(--font-sans);
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.004px;

  color: #444444;

  padding: 15px;
  box-sizing: border-box;
`;

const Hover = styled.span`
  position: relative;
  margin: 0 8px;

  &:first-child {
    margin-left: 0px;
  }
`;

export const Poper = ({ body, hover, active, onClick }) => (
  <Hover onClick={onClick}>
    {active && <Popover>{body}</Popover>}

    {cloneElement(hover, { active })}
  </Hover>
);

export const Below = styled.div`
  display: flex;
  margin: 20px 0 0;
`;
