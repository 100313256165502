import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { isBrowser, isLocalHost } from '../utils';

const AuthContext = createContext({});

const initialUser = null;

export const AuthContextProvider = ({ children, endpoint }) => {
  const [user, setUser] = useState(initialUser);
  const [isLoading, setIsLoading] = useState(false);
  const [isUserAuthChecked, setIsUserAuthChecked] = useState(false);

  const fetch = useCallback(async () => {
    setIsLoading(true);

    try {
      const data = await (await window.fetch(endpoint)).json();
      setUser(data.user);
    } catch (e) {
      setUser(initialUser);
    }

    setIsLoading(false);
    setIsUserAuthChecked(true);
  }, [endpoint]);

  useEffect(() => {
    if (isBrowser() && !isLocalHost()) {
      fetch();
    }
  }, [fetch]);

  useEffect(() => {
    const visibilityChangeHandler = () => {
      // re-fetch account data after tab was visible
      document.visibilityState === 'visible' && fetch();
    };

    document.addEventListener('visibilitychange', visibilityChangeHandler);

    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
    };
  }, [fetch]);

  const value = useMemo(
    () => ({
      isLoading,
      isUserAuthChecked,
      user,
      fetch,
    }),
    [isLoading, isUserAuthChecked, user, fetch]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuthContext = () => useContext(AuthContext);
