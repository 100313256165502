// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-builder-pages-discover-file-uploader-jsx": () => import("./../../../src/builder-pages/discover/file-uploader.jsx" /* webpackChunkName: "component---src-builder-pages-discover-file-uploader-jsx" */),
  "component---src-builder-pages-hub-startups-apply-jsx": () => import("./../../../src/builder-pages/hub/startups/apply.jsx" /* webpackChunkName: "component---src-builder-pages-hub-startups-apply-jsx" */),
  "component---src-builder-pages-hub-startups-index-jsx": () => import("./../../../src/builder-pages/hub/startups/index.jsx" /* webpackChunkName: "component---src-builder-pages-hub-startups-index-jsx" */),
  "component---src-builder-pages-migrate-from-filestack-jsx": () => import("./../../../src/builder-pages/migrate-from-filestack.jsx" /* webpackChunkName: "component---src-builder-pages-migrate-from-filestack-jsx" */),
  "component---src-builder-pages-partners-index-jsx": () => import("./../../../src/builder-pages/partners/index.jsx" /* webpackChunkName: "component---src-builder-pages-partners-index-jsx" */),
  "component---src-builder-pages-products-adaptive-delivery-jsx": () => import("./../../../src/builder-pages/products/adaptive-delivery.jsx" /* webpackChunkName: "component---src-builder-pages-products-adaptive-delivery-jsx" */),
  "component---src-builder-pages-products-intelligence-jsx": () => import("./../../../src/builder-pages/products/intelligence.jsx" /* webpackChunkName: "component---src-builder-pages-products-intelligence-jsx" */),
  "component---src-builder-pages-products-security-mdx": () => import("./../../../src/builder-pages/products/security.mdx" /* webpackChunkName: "component---src-builder-pages-products-security-mdx" */),
  "component---src-mdx-templates-customers-index-page-index-page-jsx": () => import("./../../../src/mdx/templates/customers/IndexPage/IndexPage.jsx" /* webpackChunkName: "component---src-mdx-templates-customers-index-page-index-page-jsx" */),
  "component---src-mdx-templates-customers-story-template-story-template-jsx": () => import("./../../../src/mdx/templates/customers/StoryTemplate/StoryTemplate.jsx" /* webpackChunkName: "component---src-mdx-templates-customers-story-template-story-template-jsx" */),
  "component---src-mdx-templates-learning-article-template-article-template-jsx": () => import("./../../../src/mdx/templates/learning/ArticleTemplate/ArticleTemplate.jsx" /* webpackChunkName: "component---src-mdx-templates-learning-article-template-article-template-jsx" */),
  "component---src-mdx-templates-learning-index-page-index-page-jsx": () => import("./../../../src/mdx/templates/learning/IndexPage/IndexPage.jsx" /* webpackChunkName: "component---src-mdx-templates-learning-index-page-index-page-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-bug-bounty-mdx": () => import("./../../../src/pages/about/bug-bounty.mdx" /* webpackChunkName: "component---src-pages-about-bug-bounty-mdx" */),
  "component---src-pages-about-ccpa-mdx": () => import("./../../../src/pages/about/ccpa.mdx" /* webpackChunkName: "component---src-pages-about-ccpa-mdx" */),
  "component---src-pages-about-cookie-policy-20211126-mdx": () => import("./../../../src/pages/about/cookie-policy-20211126.mdx" /* webpackChunkName: "component---src-pages-about-cookie-policy-20211126-mdx" */),
  "component---src-pages-about-faq-mdx": () => import("./../../../src/pages/about/faq.mdx" /* webpackChunkName: "component---src-pages-about-faq-mdx" */),
  "component---src-pages-about-gdpr-mdx": () => import("./../../../src/pages/about/gdpr.mdx" /* webpackChunkName: "component---src-pages-about-gdpr-mdx" */),
  "component---src-pages-about-hipaa-mdx": () => import("./../../../src/pages/about/hipaa.mdx" /* webpackChunkName: "component---src-pages-about-hipaa-mdx" */),
  "component---src-pages-about-law-enforcement-mdx": () => import("./../../../src/pages/about/law-enforcement.mdx" /* webpackChunkName: "component---src-pages-about-law-enforcement-mdx" */),
  "component---src-pages-about-modern-slavery-statement-mdx": () => import("./../../../src/pages/about/modern-slavery-statement.mdx" /* webpackChunkName: "component---src-pages-about-modern-slavery-statement-mdx" */),
  "component---src-pages-about-privacy-policy-20170915-mdx": () => import("./../../../src/pages/about/privacy_policy_20170915.mdx" /* webpackChunkName: "component---src-pages-about-privacy-policy-20170915-mdx" */),
  "component---src-pages-about-privacy-policy-20180525-mdx": () => import("./../../../src/pages/about/privacy_policy_20180525.mdx" /* webpackChunkName: "component---src-pages-about-privacy-policy-20180525-mdx" */),
  "component---src-pages-about-privacy-policy-20181012-mdx": () => import("./../../../src/pages/about/privacy_policy_20181012.mdx" /* webpackChunkName: "component---src-pages-about-privacy-policy-20181012-mdx" */),
  "component---src-pages-about-privacy-policy-20190725-mdx": () => import("./../../../src/pages/about/privacy_policy_20190725.mdx" /* webpackChunkName: "component---src-pages-about-privacy-policy-20190725-mdx" */),
  "component---src-pages-about-privacy-policy-20200117-mdx": () => import("./../../../src/pages/about/privacy_policy_20200117.mdx" /* webpackChunkName: "component---src-pages-about-privacy-policy-20200117-mdx" */),
  "component---src-pages-about-privacy-policy-20211126-mdx": () => import("./../../../src/pages/about/privacy-policy-20211126.mdx" /* webpackChunkName: "component---src-pages-about-privacy-policy-20211126-mdx" */),
  "component---src-pages-about-privacy-policy-20220615-mdx": () => import("./../../../src/pages/about/privacy-policy-20220615.mdx" /* webpackChunkName: "component---src-pages-about-privacy-policy-20220615-mdx" */),
  "component---src-pages-about-privacy-shield-notice-mdx": () => import("./../../../src/pages/about/privacy-shield-notice.mdx" /* webpackChunkName: "component---src-pages-about-privacy-shield-notice-mdx" */),
  "component---src-pages-about-security-whitepaper-mdx": () => import("./../../../src/pages/about/security-whitepaper.mdx" /* webpackChunkName: "component---src-pages-about-security-whitepaper-mdx" */),
  "component---src-pages-about-sla-mdx": () => import("./../../../src/pages/about/sla.mdx" /* webpackChunkName: "component---src-pages-about-sla-mdx" */),
  "component---src-pages-about-sub-processors-mdx": () => import("./../../../src/pages/about/sub-processors.mdx" /* webpackChunkName: "component---src-pages-about-sub-processors-mdx" */),
  "component---src-pages-about-terms-20171024-mdx": () => import("./../../../src/pages/about/terms_20171024.mdx" /* webpackChunkName: "component---src-pages-about-terms-20171024-mdx" */),
  "component---src-pages-about-terms-20180525-mdx": () => import("./../../../src/pages/about/terms_20180525.mdx" /* webpackChunkName: "component---src-pages-about-terms-20180525-mdx" */),
  "component---src-pages-about-terms-20201023-mdx": () => import("./../../../src/pages/about/terms_20201023.mdx" /* webpackChunkName: "component---src-pages-about-terms-20201023-mdx" */),
  "component---src-pages-about-terms-20211126-mdx": () => import("./../../../src/pages/about/terms-20211126.mdx" /* webpackChunkName: "component---src-pages-about-terms-20211126-mdx" */),
  "component---src-pages-about-tia-mdx": () => import("./../../../src/pages/about/tia.mdx" /* webpackChunkName: "component---src-pages-about-tia-mdx" */),
  "component---src-pages-about-trust-mdx": () => import("./../../../src/pages/about/trust.mdx" /* webpackChunkName: "component---src-pages-about-trust-mdx" */),
  "component---src-pages-about-use-cases-mdx": () => import("./../../../src/pages/about/use-cases.mdx" /* webpackChunkName: "component---src-pages-about-use-cases-mdx" */),
  "component---src-pages-about-user-data-deletion-mdx": () => import("./../../../src/pages/about/user-data-deletion.mdx" /* webpackChunkName: "component---src-pages-about-user-data-deletion-mdx" */),
  "component---src-pages-api-jsx": () => import("./../../../src/pages/api.jsx" /* webpackChunkName: "component---src-pages-api-jsx" */),
  "component---src-pages-cdn-image-cdn-jsx": () => import("./../../../src/pages/cdn/image-cdn.jsx" /* webpackChunkName: "component---src-pages-cdn-image-cdn-jsx" */),
  "component---src-pages-cdn-image-processing-jsx": () => import("./../../../src/pages/cdn/image-processing.jsx" /* webpackChunkName: "component---src-pages-cdn-image-processing-jsx" */),
  "component---src-pages-cdn-index-jsx": () => import("./../../../src/pages/cdn/index.jsx" /* webpackChunkName: "component---src-pages-cdn-index-jsx" */),
  "component---src-pages-cdn-speed-optimization-jsx": () => import("./../../../src/pages/cdn/speed-optimization.jsx" /* webpackChunkName: "component---src-pages-cdn-speed-optimization-jsx" */),
  "component---src-pages-company-awards-mdx": () => import("./../../../src/pages/company/awards.mdx" /* webpackChunkName: "component---src-pages-company-awards-mdx" */),
  "component---src-pages-company-index-jsx": () => import("./../../../src/pages/company/index.jsx" /* webpackChunkName: "component---src-pages-company-index-jsx" */),
  "component---src-pages-demo-jsx": () => import("./../../../src/pages/demo.jsx" /* webpackChunkName: "component---src-pages-demo-jsx" */),
  "component---src-pages-demos-adaptive-delivery-jsx": () => import("./../../../src/pages/demos/adaptive-delivery.jsx" /* webpackChunkName: "component---src-pages-demos-adaptive-delivery-jsx" */),
  "component---src-pages-demos-image-transformations-jsx": () => import("./../../../src/pages/demos/image-transformations.jsx" /* webpackChunkName: "component---src-pages-demos-image-transformations-jsx" */),
  "component---src-pages-discover-api-jsx": () => import("./../../../src/pages/discover/api.jsx" /* webpackChunkName: "component---src-pages-discover-api-jsx" */),
  "component---src-pages-discover-cdn-jsx": () => import("./../../../src/pages/discover/cdn.jsx" /* webpackChunkName: "component---src-pages-discover-cdn-jsx" */),
  "component---src-pages-discover-configure-jsx": () => import("./../../../src/pages/discover/configure.jsx" /* webpackChunkName: "component---src-pages-discover-configure-jsx" */),
  "component---src-pages-discover-demo-jsx": () => import("./../../../src/pages/discover/demo.jsx" /* webpackChunkName: "component---src-pages-discover-demo-jsx" */),
  "component---src-pages-discover-how-to-transfer-your-files-mdx": () => import("./../../../src/pages/discover/how-to-transfer-your-files.mdx" /* webpackChunkName: "component---src-pages-discover-how-to-transfer-your-files-mdx" */),
  "component---src-pages-discover-image-cdn-jsx": () => import("./../../../src/pages/discover/image-cdn.jsx" /* webpackChunkName: "component---src-pages-discover-image-cdn-jsx" */),
  "component---src-pages-discover-image-processing-jsx": () => import("./../../../src/pages/discover/image-processing.jsx" /* webpackChunkName: "component---src-pages-discover-image-processing-jsx" */),
  "component---src-pages-discover-migrate-from-filestack-jsx": () => import("./../../../src/pages/discover/migrate-from-filestack.jsx" /* webpackChunkName: "component---src-pages-discover-migrate-from-filestack-jsx" */),
  "component---src-pages-discover-migrate-mdx": () => import("./../../../src/pages/discover/migrate.mdx" /* webpackChunkName: "component---src-pages-discover-migrate-mdx" */),
  "component---src-pages-discover-sem-jsx": () => import("./../../../src/pages/discover/sem.jsx" /* webpackChunkName: "component---src-pages-discover-sem-jsx" */),
  "component---src-pages-discover-uploadcare-jsx": () => import("./../../../src/pages/discover/uploadcare.jsx" /* webpackChunkName: "component---src-pages-discover-uploadcare-jsx" */),
  "component---src-pages-features-jsx": () => import("./../../../src/pages/features.jsx" /* webpackChunkName: "component---src-pages-features-jsx" */),
  "component---src-pages-guides-browse-uploaded-files-mdx": () => import("./../../../src/pages/guides/browse-uploaded-files.mdx" /* webpackChunkName: "component---src-pages-guides-browse-uploaded-files-mdx" */),
  "component---src-pages-guides-gif-2-video-mdx": () => import("./../../../src/pages/guides/gif2video.mdx" /* webpackChunkName: "component---src-pages-guides-gif-2-video-mdx" */),
  "component---src-pages-guides-how-to-transfer-your-files-mdx": () => import("./../../../src/pages/guides/how-to-transfer-your-files.mdx" /* webpackChunkName: "component---src-pages-guides-how-to-transfer-your-files-mdx" */),
  "component---src-pages-guides-making-images-responsive-mdx": () => import("./../../../src/pages/guides/making-images-responsive.mdx" /* webpackChunkName: "component---src-pages-guides-making-images-responsive-mdx" */),
  "component---src-pages-guides-map-image-operations-mdx": () => import("./../../../src/pages/guides/map-image-operations.mdx" /* webpackChunkName: "component---src-pages-guides-map-image-operations-mdx" */),
  "component---src-pages-guides-watermarks-mdx": () => import("./../../../src/pages/guides/watermarks.mdx" /* webpackChunkName: "component---src-pages-guides-watermarks-mdx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pricing-jsx": () => import("./../../../src/pages/pricing.jsx" /* webpackChunkName: "component---src-pages-pricing-jsx" */),
  "component---src-pages-products-document-conversion-jsx": () => import("./../../../src/pages/products/document-conversion.jsx" /* webpackChunkName: "component---src-pages-products-document-conversion-jsx" */),
  "component---src-pages-products-file-uploader-js-jsx": () => import("./../../../src/pages/products/file-uploader/js.jsx" /* webpackChunkName: "component---src-pages-products-file-uploader-js-jsx" */),
  "component---src-pages-products-file-uploader-jsx": () => import("./../../../src/pages/products/file-uploader.jsx" /* webpackChunkName: "component---src-pages-products-file-uploader-jsx" */),
  "component---src-pages-schedule-demo-jsx": () => import("./../../../src/pages/schedule-demo.jsx" /* webpackChunkName: "component---src-pages-schedule-demo-jsx" */),
  "component---src-pages-solutions-devshops-jsx": () => import("./../../../src/pages/solutions/devshops.jsx" /* webpackChunkName: "component---src-pages-solutions-devshops-jsx" */),
  "component---src-pages-solutions-ecommerce-jsx": () => import("./../../../src/pages/solutions/ecommerce.jsx" /* webpackChunkName: "component---src-pages-solutions-ecommerce-jsx" */),
  "component---src-pages-solutions-elearning-jsx": () => import("./../../../src/pages/solutions/elearning.jsx" /* webpackChunkName: "component---src-pages-solutions-elearning-jsx" */),
  "component---src-pages-solutions-healthcare-jsx": () => import("./../../../src/pages/solutions/healthcare.jsx" /* webpackChunkName: "component---src-pages-solutions-healthcare-jsx" */),
  "component---src-pages-solutions-marketplaces-jsx": () => import("./../../../src/pages/solutions/marketplaces.jsx" /* webpackChunkName: "component---src-pages-solutions-marketplaces-jsx" */),
  "component---src-pages-upload-sources-mdx": () => import("./../../../src/pages/upload_sources.mdx" /* webpackChunkName: "component---src-pages-upload-sources-mdx" */),
  "component---src-pages-widget-configure-jsx": () => import("./../../../src/pages/widget/configure.jsx" /* webpackChunkName: "component---src-pages-widget-configure-jsx" */)
}

