/* Vendors */
import React from 'react';
import styled from 'styled-components';

/* Components */
import { StoryBreak } from '../StoryBreak/StoryBreak';
import { Link } from '../../../components/Link/Link';
import { Button } from '../Button/Button';

/* Utils */
import { withMedia } from '../../../utils/withMedia';

/* Types */

/* Styles */

const StyledStoryBreak = styled(StoryBreak)`
  margin: 20px 0px 36px 0px;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 48px 80px;

  @media (${withMedia('--small')}) {
    grid-template-columns: initial;
    grid-template-rows: auto auto;
  }
`;

const InfoContainer = styled.ul`
  list-style: none;
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: 16px;

  @media (${withMedia('--small')}) {
    font-size: 32px;
    justify-content: center;
  }
`;

const StyledInfoItem = styled.li``;

const InfoKey = styled.div`
  font-family: var(--font-headings);
  font-size: 13px;
  line-height: 1.23;
  letter-spacing: 0.9px;
  color: var(--color-grey);
  text-transform: uppercase;
  margin-bottom: 2px;
`;

const InfoValue = styled.div`
  font-family: var(--font-headings);
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: 0.8px;
  color: var(--color-dark);
  padding: 0;
`;

const InfoLink = styled(Link)`
  font-family: var(--font-headings);
  font-size: 18px;
  line-height: 1.11;
  letter-spacing: 0.8px;
  color: var(--color-grey);
  margin-top: 8px;
`;

const Description = styled.div`
  font-family: var(--font-headings);
  font-size: 26px;
  line-height: 1.46;
  color: var(--color-dark);
`;

/* Content */

const infoKeys = ['industry', 'headquarters', 'employees', 'tenure'];

/* Component */

const InfoItem = ({ itemKey, companyInfo }) =>
  companyInfo[itemKey] && (
    <StyledInfoItem>
      <InfoKey>{itemKey.replace(/([A-Z])/g, ' $1')}</InfoKey>
      <InfoValue>{companyInfo[itemKey]}</InfoValue>
    </StyledInfoItem>
  );

export const StoryCompanySection = ({ className, companyInfo, children, button }) => (
  <StyledStoryBreak className={className} visual="ecru-white" maxWidth={980}>
    <Content>
      <InfoContainer>
        {infoKeys.map((itemKey) => (
          <InfoItem key={itemKey} itemKey={itemKey} companyInfo={companyInfo} />
        ))}
        <li>
          <InfoLink rel="noopener" visual="strong" to={companyInfo.url}>
            {companyInfo.urlText}
          </InfoLink>
        </li>
        {Boolean(button) && <Button buttonlink={button.href}>{button.text}</Button>}
      </InfoContainer>
      <Description>{children}</Description>
    </Content>
  </StyledStoryBreak>
);

/* default props */
StoryCompanySection.defaultProps = {};
