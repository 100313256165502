/* Vendors */
import React from 'react';
import styled from 'styled-components';

/* Components */

/* Utils */
import { PaddingBox } from '../../mdx/components/PaddingBox/PaddingBox';
import { withMedia } from '../../utils/withMedia';

/* Types */

/* Styles */

const Component = styled(PaddingBox)`
  --content-width: ${(props) =>
    typeof props.maxWidth === 'object' ? props.maxWidth[0] : props.maxWidth}px;
  --padding-left: 0;
  --padding-right: 0;

  max-width: var(--content-width);
  margin: 0 auto;
  width: 100%;

  ${(props) =>
    props.maxWidth[1] &&
    `
    @media (${withMedia('--large')}) {
      --content-width: ${
        typeof props.maxWidth === 'object' ? props.maxWidth[1] : props.maxWidth
      }px;;
    }
  `}
`;

/* Component */

const defaultPaddings = {
  verticalBase: 80,
  horizontalBase: 120,
};

export const Content = ({ className, children, ...rest }) => (
  <Component className={className} {...defaultPaddings} {...rest}>
    {children}
  </Component>
);

/* default props */
Content.defaultProps = {
  maxWidth: [1176],
};
