import { Link } from '~/src/components/Link';
import { Code } from "@uc-common/code";
import { smartCompressionExample, qualitySmartInline } from "~/src/learning/code-examples/code-examples";
import * as React from 'react';
export default {
  Link,
  Code,
  smartCompressionExample,
  qualitySmartInline,
  React
};