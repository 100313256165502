/**
 * @param {string | null} string
 * @returns {[boolean, import('url').URL | null]} Boolean, URL
 */
const isValidHttpUrl = (string = '') => {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return [false, null];
  }

  return [url.protocol === 'http:' || url.protocol === 'https:', url];
};

export const shouldBeTracked = (pathname = '') => {
  const [isURL, url] = isValidHttpUrl(pathname);

  return /^\/accounts\/(login|signup)(\/)?(\?.*)?$/.test(isURL ? url.pathname : pathname);
};
