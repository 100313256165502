import styled from 'styled-components';

export const BadgesBox = styled.div`
  display: inline-flex;
`;

export const Badge = styled.span`
  display: block;

  font-size: 14px;
  font-weight: 400;
  font-family: var(--font-sans);
  padding: 0 8px;
  margin-right: 8px;
  border-radius: 3px;
  background-color: var(--color-yellow);
`;
