import { css } from 'styled-components';
import { InputSizeEnum } from './enums';

export const visuallyHiddenStyles = css`
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
`;

export const inputSizeCss = css`
  ${({ size }) => {
    switch (size) {
      case InputSizeEnum.EXTRA_SMALL:
        return css`
          height: 32px;
          padding: 0 12px;
          font-size: 14px;
        `;
      case InputSizeEnum.SMALL:
        return css`
          height: 38px;
          padding: 10px 12px;
          font-size: 14px;
        `;

      case InputSizeEnum.NORMAL:
        return css`
          height: 44px;
          padding: 10px 16px;
          font-size: 16px;
          line-height: 22px;
        `;

      case InputSizeEnum.LARGE:
        return css`
          height: 52px;
          padding: 14px 16px;
          font-size: 16px;
          line-height: 22px;
        `;
      default:
        // eslint-disable-next-line no-console
        console.warn(`Input size "${size}" not implemented`);
    }
  }}
`;

export const baseInputCss = ({ theme }) => css`
  font-family: inherit;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  appearance: none;
  width: 100%;

  font-size: inherit;
  border-radius: 6px;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  background-color: ${theme.defaultBackgroundColor};
  color: ${theme.defaultColor};
  border: ${theme.defaultBorder ? theme.defaultBorder : 'none'};
  box-shadow: ${theme.defaultBoxShadow};

  &:active {
    background-color: ${theme.defaultBackgroundColor};
    color: ${theme.defaultColor};
    border: ${theme.defaultBorder};
    box-shadow: ${theme.defaultBoxShadow};
  }

  &:hover {
    background-color: ${theme.hoverBackgroundColor};
    color: ${theme.hoverColor};
    border: ${theme.hoverBorder};
    box-shadow: ${theme.hoverBoxShadow};
  }

  &:focus {
    outline: 0;
    background-color: ${theme.focusBackgroundColor};
    color: ${theme.focusColor};
    border: ${theme.focusBorder};
    box-shadow: ${theme.focusBoxShadow};
  }

  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active {
      background-color: ${theme.disabledBackgroundColor};
      border: ${theme.disabledBorder};
      box-shadow: ${theme.disabledBoxShadow};
      color: ${theme.disabledColor};
      pointer-events: none;
    }
  }

  ::placeholder {
    color: ${theme.placeholderColor};
  }

  :-ms-input-placeholder {
    color: ${theme.placeholderColor};
  }

  ::-ms-input-placeholder {
    color: ${theme.placeholderColor};
  }
`;
