import styled, { keyframes } from 'styled-components';

const spinner = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const InputSpinner = styled.div`
  width: 1em;
  height: 1em;
  border: solid 2px transparent;
  border-top-color: currentColor;
  border-left-color: currentColor;
  border-radius: 50%;
  animation: ${spinner} 400ms linear infinite;
`;
