export const isBrowser = () => typeof window !== 'undefined';

export const isLocalHost = () => window.location.href.indexOf('localhost') !== -1;

export const encode = (data) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
};

export const getStylesOfElement = (element, propertyName) => {
  if (!(element instanceof window.HTMLElement)) {
    // eslint-disable-next-line no-console
    console.warn('Invalid element was provided.');
    return null;
  }

  const elementStyles = window.getComputedStyle(element);

  return propertyName ? elementStyles.getPropertyValue(propertyName) : elementStyles;
};

export const filterXSS = (html) => html.replace(/</g, '&lt;').replace(/>/g, '&gt;');
