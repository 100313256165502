import React from 'react';

export const IconArrowRight = () => (
  <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 4.074H6.356L3.64 6.79V7H4.83L8.26 3.57V3.43L4.83 0H3.64V0.21L6.384 2.954H0V4.074Z"
      fill="currentColor"
    />
  </svg>
);

export const IconClose = () => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.43057 6L12 11.5694L11.5694 12L6 6.43057L0.430578 12L8.6851e-06 11.5694L5.56943 6L0 0.43057L0.430569 0L6 5.56944L11.5694 0L12 0.43057L6.43057 6Z"
      fill="currentColor"
    />
  </svg>
);
