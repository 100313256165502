/**
 * Deserialize modifiers from string to key-value object Example: `/scale_crop/100x100/center/` =>
 * `{scale_crop: ['100x100', 'center']}`
 *
 * @param {string} modifiers
 */
export function deserializeModifiers(modifiers) {
  return modifiers
    .split(/\/?-\/?/)
    .filter((str) => str.length > 0)
    .map((str) => str.split('/').filter((str) => str.length > 0))
    .reduce((map, arr) => map.set(arr[0], arr.slice(1)), new Map());
}

/**
 * Multiply size values for scale_crop, resize and preivew at modifiers map
 *
 * @param {any} modifiers
 */
function multiplySize(modifiers) {
  const multiplySizeFor = ['scale_crop', 'resize', 'preview'];
  const clone = new Map(modifiers);

  for (const [prop, propValue] of clone) {
    if (multiplySizeFor.includes(prop)) {
      const multipliedValue = propValue.map((val) =>
        val.replace(/(\d*)x(\d*)/g, (str, m1, m2) => {
          const width = m1 ? parseInt(m1, 10) * 2 : '';
          const height = m2 ? parseInt(m2, 10) * 2 : '';

          return `${width}x${height}`;
        })
      );

      clone.set(prop, multipliedValue);
    }
  }

  return clone;
}

/**
 * Serialize modifiers object to string
 *
 * @param {string} modifiers
 */
export function serializeModifiers(modifiers) {
  const result = [...Array.from(modifiers)]
    .map(([key, val]) => `${key}/${val.join('/')}`)
    .join('/-/');

  if (result) {
    return `-/${result}/`;
  }

  return '';
}

/**
 * Build CDN url for file with specified UUID and modifiers
 *
 * @param {string} uuid
 * @param {string} modifiers
 */
export function imageUrl(uuid, modifiers) {
  if (!modifiers) {
    return `//ucarecdn.com/${uuid}/`;
  }

  const serializedModifiers = serializeModifiers(modifiers);

  return `//ucarecdn.com/${uuid}/${serializedModifiers}`;
}

/**
 * Extends modifiers map with defaults for progressive, format and quality
 *
 * @param {string} modifiers
 */
export function extendWithDefaults(modifiers) {
  const defaults = new Map([
    ['progressive', ['yes']],
    ['format', ['auto']],
    ['quality', ['normal']],
  ]);
  const endingModifiers = Array.from(defaults.keys());

  const sortFn = (a, b) => {
    const aIsEnding = endingModifiers.includes(a[0]);
    const bIsEnding = endingModifiers.includes(b[0]);

    if (aIsEnding && bIsEnding) {
      return endingModifiers.indexOf(a[0]) > endingModifiers.indexOf(b[0]) ? 1 : -1;
    }

    if (aIsEnding) {
      return 1;
    }

    if (bIsEnding) {
      return -1;
    }

    return 0;
  };

  // merge input modifiers with the default ones
  const mergedMap = new Map([...Array.from(defaults), ...Array.from(modifiers)]);

  // place default modifiers in the end
  return new Map([...mergedMap].sort(sortFn));
}

/**
 * Get css prefix with overlay image
 *
 * @param {string} [overlay]
 */
function getOverlayPrefix(overlay) {
  return overlay ? `url(${imageUrl(overlay)}), ` : '';
}

/**
 * Generate `background-image` property with raw url to cdn
 *
 * @param {string} uuid
 * @param {string} [overlay]
 */
function backgroundImageRaw(uuid, overlay) {
  return `background-image: ${getOverlayPrefix(overlay)}url(${imageUrl(uuid)});`;
}

/**
 * Generate css `background-image` property using image-set for 1x and 2x with fallback Accepts UUID
 * and modifiers string Searches for parttern /000x000/ and multiplies it for 2x screens (except for
 * the /crop/ modifier)
 *
 * @param {string} uuid
 * @param {string} modifiers
 * @param {string} [overlay]
 * @returns {string}
 */
function backgroundImageSimple(uuid, modifiers, overlay) {
  const modifiersMap = deserializeModifiers(modifiers);

  const modifiers1x = new Map(modifiersMap);
  const modifiers2x = multiplySize(modifiersMap);

  const images = [
    `url(${imageUrl(uuid, extendWithDefaults(modifiers1x))}) 1x`,
    `url(${imageUrl(uuid, extendWithDefaults(modifiers2x.set('quality', ['lightest'])))}) 2x`,
  ];
  const overlayPrefix = getOverlayPrefix(overlay);
  const fallback = `url(${imageUrl(uuid, extendWithDefaults(modifiers1x))})`;

  return (
    `background-image: ${overlayPrefix}${fallback};` +
    `background-image: ${overlayPrefix}image-set(${images.join(',')});`
  );
}

/**
 * Generate css `background-image` property using image-set for provided dpi with fallback Accepts
 * UUID and object with modifiers per dpi Doesn't multiply sizes
 *
 * @param {string} uuid
 * @param {any} modifiers
 * @param {string} [overlay]
 */
export function backgroundImageCustom(uuid, modifiers, overlay) {
  const lines = Object.keys(modifiers).map((dpi) => {
    const modifiersMap = deserializeModifiers(modifiers[dpi]);

    return `url(${imageUrl(uuid, extendWithDefaults(modifiersMap))}) ${dpi}`;
  });

  const overlayPrefix = getOverlayPrefix(overlay);
  const fallback = `${overlayPrefix}url(${imageUrl(
    uuid,
    extendWithDefaults(deserializeModifiers(modifiers['1x']))
  )})`;

  return `background-image: ${fallback};background-image: image-set(${lines.join(',')});`;
}

/**
 * Generate css `background-image` property to handle CDN images
 *
 * @param {string} uuid
 * @param {string} modifiers
 * @param {string} [overlay]
 */
export function backgroundImage(uuid, modifiers, overlay) {
  if (!modifiers) {
    return backgroundImageRaw(uuid, overlay);
  }
  if (modifiers && typeof modifiers === 'string') {
    return backgroundImageSimple(uuid, modifiers, overlay);
  }
  if (modifiers) {
    return backgroundImageCustom(uuid, modifiers, overlay);
  }

  return '';
}

/**
 * Generate string to be used as srcset attribute Accepts UUID and modifiers string Searches for
 * parttern /000x000/ and multiplies it for 2x screens (except for the /crop/ modifier)
 *
 * @param {string} uuid
 * @param {string} modifiers
 * @returns {string}
 */
export function srcSet(uuid, modifiers) {
  const modifiersMap = deserializeModifiers(modifiers);

  const modifiers1x = new Map(modifiersMap);
  const modifiers2x = multiplySize(modifiersMap);

  const images = [
    `${imageUrl(uuid, extendWithDefaults(modifiers1x))} 1x`,
    `${imageUrl(uuid, extendWithDefaults(modifiers2x.set('quality', ['lightest'])))} 2x`,
  ];

  return images.join(',');
}

/**
 * Generate string to be used as img src fallback Accepts UUID and modifiers string
 *
 * @param {string} uuid
 * @param {string} modifiers
 * @returns {string}
 */
export function imgFallback(uuid, modifiers) {
  const modifiersMap = deserializeModifiers(modifiers);
  const modifiers1x = new Map(modifiersMap);

  return `${imageUrl(uuid, extendWithDefaults(modifiers1x))}`;
}
