import ReactSelect, { components } from 'react-select';
import styled, { css } from 'styled-components';

import { ReactComponent as DropwDownIcon } from '../assets/dropdown.svg';
import { baseInputCss } from '../../input';
import { SELECT_CLASSNAME_PREFIX, INPUT_SELECT_FOCUSED_SELECTOR } from '../constants';
import { inputSelectSizeCss } from './css';

const { Control, SelectContainer } = components;

export const StyledIndicator = styled(DropwDownIcon)(
  ({ theme: { placeholderColor } }) => css`
    opacity: 0.3;
    color: ${placeholderColor};
    margin-left: 4px;
    transition: opacity 0.3s ease;
  `
);

export const StyledSelectControl = styled(Control)(
  ({ theme }) => css`
    && {
      ${baseInputCss};
      ${inputSelectSizeCss};
      cursor: pointer;

      &:hover {
        ${/* sc-selector */ StyledIndicator} {
          opacity: 1;
        }
      }

      &${INPUT_SELECT_FOCUSED_SELECTOR} {
        outline: 0;
        box-shadow: ${theme.focusBoxShadow};
        background-color: ${theme.focusBackgroundColor};
        color: ${theme.focusColor};
        border: ${theme.focusBorder};

        ${/* sc-selector */ StyledIndicator} {
          opacity: 1;
        }
      }

      &.${SELECT_CLASSNAME_PREFIX}__control--menu-is-open {
        .${SELECT_CLASSNAME_PREFIX}__indicators {
          transform: scale(1, -1);
        }
      }
    }
  `
);

export const StyledSelectContainer = styled(SelectContainer)``;

export const StyledSelect = styled(ReactSelect)(
  ({ theme }) => css`
    .${SELECT_CLASSNAME_PREFIX}__indicators {
      transition: transform 0.3s;
    }

    .${SELECT_CLASSNAME_PREFIX}__value-container {
      padding: 0;
      position: static;
    }

    .${SELECT_CLASSNAME_PREFIX}__placeholder {
      margin: 0;
      color: ${theme.placeholderColor};
    }

    .${SELECT_CLASSNAME_PREFIX}__menu {
      font-size: inherit;
      z-index: 110;
      background-color: ${theme.defaultBackgroundColor};
      border: ${theme.menuBorder};
    }

    .${SELECT_CLASSNAME_PREFIX}__option {
      display: flex;
      color: ${theme.defaultColor};

      &--is-selected {
        background-color: ${theme.selectedOptionBackground};
      }

      &--is-focused {
        background-color: ${theme.focusedOptionBackground};
      }

      &:active {
        background-color: ${theme.activeOptionBackground};
      }

      > div {
        margin-right: 8px;
      }
    }

    .${SELECT_CLASSNAME_PREFIX}__input-container {
      margin: 0;
      padding: 0;
    }

    .${SELECT_CLASSNAME_PREFIX}__single-value {
      color: ${theme.defaultColor};
    }
  `
);
