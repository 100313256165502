/* Vendors */
import React from 'react';
import styled from 'styled-components';

/* Components */

/* Utils */

/* Types */

/* Styles */

const StyledButtonSection = styled.p`
  margin: 50px 0 30px 0;
`;

const StyledButton = styled.a`
  font-family: var(--font-headings);
  font-size: 18px;
  line-height: 1.52;
  border-radius: 6px;
  color: #ffffff;
  background-color: #191919;
  margin: 50px 0 30px 0;
  padding: 10px 50px;
  text-align: center;
  display: inline;
  text-decoration: none;
`;

/* Button */
export const Button = ({ children, buttonlink }) => (
  <StyledButtonSection>
    <StyledButton href={buttonlink} target="_blank">
      {children}
    </StyledButton>
  </StyledButtonSection>
);

/* default props */
Button.defaultProps = {};
