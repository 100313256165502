import { InputStateEnum } from './enums';

export const INPUT_THEME_DEFAULT = {
  [InputStateEnum.IDLE]: {
    defaultBackgroundColor: '#ffffff',
    defaultColor: '#000000',
    defaultBorder: '1px solid #d1d1d1',

    hoverBoxShadow: '0 0 0 1px #52b0ff',
    hoverBackgroundColor: '#ffffff',
    hoverColor: '#000000',
    hoverBorder: '1px solid #52b0ff',

    focusBoxShadow: '0 0 0 1px #238de6',
    focusBackgroundColor: '#ffffff',
    focusColor: '#000000',
    focusBorder: '1px solid #238de6',

    disabledBackgroundColor: '#ffffff',
    disabledColor: 'rgba(128, 128, 128, 0.5)',
    disabledBorder: '1px solid #d1d1d1',

    placeholderColor: '#B5B5B5',
  },
  [InputStateEnum.ERROR]: {
    defaultBackgroundColor: '#ffffff',
    defaultColor: '#000000',
    defaultBorder: '1px solid #de95a2',

    hoverBoxShadow: '0 0 0 1px #bd2a45',
    hoverBackgroundColor: '#ffffff',
    hoverColor: '#000000',
    hoverBorder: '1px solid #bd2a45',

    focusBoxShadow: '0 0 0 1px #bd2a45',
    focusBackgroundColor: '#ffffff',
    focusColor: '#000000',
    focusBorder: '1px solid #bd2a45',

    disabledBackgroundColor: '#ffffff',
    disabledColor: 'rgba(128, 128, 128, 0.5)',
    disabledBorder: '1px solid #d1d1d1',

    placeholderColor: '#B5B5B5',
  },
};

export const INPUT_THEME_SPACE = {
  [InputStateEnum.IDLE]: {
    defaultBackgroundColor: '#EEEEEE',
    defaultColor: '#000000',

    hoverBackgroundColor: '#E7E9EA',
    hoverColor: '#000000',

    focusBackgroundColor: '#E0E2E4',
    focusColor: '#000000',

    disabledBackgroundColor: '#EEEEEE',
    disabledColor: '#B5B5B5',

    placeholderColor: 'rgba(0, 0, 0, 0.25)',
  },
  [InputStateEnum.ERROR]: {
    defaultBackgroundColor: '#F1EDED',
    defaultColor: '#000000',

    hoverBackgroundColor: '#EFECEC',
    hoverColor: '#000000',

    focusBackgroundColor: '#EEEBEB',
    focusColor: '#000000',

    disabledBackgroundColor: '#EEEEEE',
    disabledColor: '#B5B5B5',

    placeholderColor: 'rgba(0, 0, 0, 0.25)',
  },
};

export const INPUT_THEME_DARK = {
  [InputStateEnum.IDLE]: {
    defaultBackgroundColor: '#000000',
    defaultColor: '#ffffff',
    defaultBorder: '2px solid #47525C',

    hoverBackgroundColor: '#000000',
    hoverColor: '#ffffff',
    hoverBorder: '2px solid #8F99A1',

    focusBackgroundColor: '#000000',
    focusColor: '#ffffff',
    focusBorder: '2px solid #2D7EFF',

    disabledBackgroundColor: '#16191C',
    disabledColor: '#ffffff',
    disabledBorder: '2px solid #242B31',

    placeholderColor: '#4D565F',
  },
  [InputStateEnum.ERROR]: {
    defaultBackgroundColor: '#000000',
    defaultColor: '#ffffff',
    defaultBorder: '2px solid #773A48',

    hoverBackgroundColor: '#000000',
    hoverColor: '#ffffff',
    hoverBorder: '2px solid #CD4A6A',

    focusBackgroundColor: '#000000',
    focusColor: '#ffffff',
    focusBorder: '2px solid #CD4A6A',

    disabledBackgroundColor: '#16191C',
    disabledColor: '#ffffff',
    disabledBorder: '2px solid #59373F',

    placeholderColor: '#4D565F',
  },
};
