import { useState } from 'react';
import { useLeadFormContext } from './LeadFormContext';
import { useServerValidation } from '../use-server-validation';

export const FormStatusTypeEnum = {
  INITIAL: 'initial',
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
};

// id values are taken from: https://uploadcare.atlassian.net/browse/WEB-946
export const FORM_ID_ENUM = {
  DEMO_REQUEST: 'a4e53225-d9d8-4915-a5cb-27a8c4f70e8d',
  PARTNERSHIP: '2e424886-0165-462a-b19c-fdb1b17d2994',
  STARTUP_DISCOUNT: 'a9821aff-acd8-443a-8afc-3fbcc1f8cbf2',
  EMAIL_SUBSCRIPTION: '8368125a-90c4-11ec-b909-0242ac120002',
};

export const useLeadForm = (formId, setFormError, ignoreFields = []) => {
  const [status, setStatus] = useState(FormStatusTypeEnum.INITIAL);
  const [error, setError] = useState(null);
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { endpoint } = useLeadFormContext();

  const { handleError: handleValidationError, validationErrors } = useServerValidation(
    setFormError,
    error,
    ignoreFields
  );

  const run = async (data) => {
    const body = JSON.stringify({
      form_id: formId,
      ...data,
    });

    const handleResponse = async (response) => {
      if (response.ok) {
        setResponse(response);
        setStatus(FormStatusTypeEnum.FULFILLED);
      } else {
        response.data = await response.json();
        const error = new Error(response.statusText || response.status);
        error.response = response;
        throw error;
      }
    };

    const catchByRollbar = (error) => {
      if (typeof window.Rollbar !== 'undefined') {
        window.Rollbar?.error?.(
          'Failed to send lead form data',
          {
            formType: Object.keys(FORM_ID_ENUM).find((key) => FORM_ID_ENUM[key] === formId),
            response: {
              status: error.response?.status,
              text: error.response?.data ?? error.message,
            },
          },
          error
        );
      }
    };

    const handleError = (error) => {
      try {
        handleValidationError(error);
      } catch (error) {
        catchByRollbar(error);
      }
    };

    try {
      setStatus(FormStatusTypeEnum.PENDING);
      setIsLoading(true);
      const response = await window.fetch(endpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body,
      });
      await handleResponse(response);
    } catch (error) {
      handleError(error);
      setError(error);
      setStatus(FormStatusTypeEnum.REJECTED);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    run,
    error,
    status,
    response,
    isLoading,
    validationErrors,
  };
};
