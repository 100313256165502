import React from 'react';
import PropTypes from 'prop-types';
import { getSrc } from './utils';

export const Image = ({
  uuid,
  src = '',
  loading = 'lazy',
  alt = '',
  options = { quality: 'smart', format: 'auto' },
  ...props
}) => {
  const resultSrc = getSrc({ uuid, src, options });

  return <img {...props} alt={alt} src={resultSrc} loading={loading} />;
};

Image.propTypes = {
  uuid: PropTypes.string,
  src: PropTypes.string,
  alt: PropTypes.string,
  loading: PropTypes.string,
  options: PropTypes.object,
};
