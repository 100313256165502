import styled from 'styled-components';
import { baseInputCss, inputSizeCss } from './css';

export const StyledInput = styled.input`
  ${baseInputCss}
  ${inputSizeCss}
`;

export const InputLabel = styled.label`
  font-size: 15px;
  line-height: 1.4;
  color: #626262;
  display: inline-flex;
  align-items: center;
`;
