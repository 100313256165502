import { Heading } from '~/src/mdx/components/Heading/Heading';
import { StoryCompanySection } from '~/src/mdx/components/StoryCompanySection/StoryCompanySection';
import { Paragraph } from '~/src/mdx/components/Paragraph/Paragraph';
import { StoryBreak } from '~/src/mdx/components/StoryBreak/StoryBreak';
import { Quote } from '~/src/mdx/components/Quote/Quote';
import { PaddingBox } from '~/src/mdx/components/PaddingBox/PaddingBox';
import * as React from 'react';
export default {
  Heading,
  StoryCompanySection,
  Paragraph,
  StoryBreak,
  Quote,
  PaddingBox,
  React
};