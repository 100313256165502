import styled from 'styled-components';

export const ULListContainer = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 13px;
  font-size: 15px;
  list-style: none;
  color: #ffffff;
`;

export const ULListItem = styled.li`
  display: inline-flex;
  align-items: center;
`;

export const ULListCheckmark = styled.svg`
  display: inline-block;
  margin-right: var(--icon-margin, 9px);
  width: var(--icon-size, 24px);
  height: var(--icon-size, 24px);
  color: var(--icon-color, #000000);
`;
