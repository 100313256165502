import { Link } from '~/src/components/Link';
import { Code } from "@uc-common/code";
import { faceCropExample, faceCropExample2 } from "~/src/learning/code-examples/code-examples";
import * as React from 'react';
export default {
  Link,
  Code,
  faceCropExample,
  faceCropExample2,
  React
};