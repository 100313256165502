import { Button, ButtonTypeEnum } from '@uc-common/button';
import { Code } from '@uc-common/code';
import { SectionWrapper } from '~/src/components/CommonStyles';
import { Link } from "~/src/components/Link";
import { track } from "~/src/components/forStartGuide";
import * as React from 'react';
export default {
  Button,
  ButtonTypeEnum,
  Code,
  SectionWrapper,
  Link,
  track,
  React
};