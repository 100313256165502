import styled, { css } from 'styled-components';

export const Container = styled.div(
  ({ theme: { breakpoints, tablet, laptop, desktop } }) => css`
    --container-gutter-x: 15px;
    max-width: 100%;
    padding: 0 var(--container-gutter-x);
    margin: 0 auto;

    ${tablet()} {
      --container-gutter-x: 32px;
    }

    ${laptop()} {
      --container-gutter-x: 45px;
    }

    ${desktop()} {
      max-width: ${breakpoints[2]};
      --container-gutter-x: 80px;
    }
  `
);
