import React from 'react';
import styled, { css } from 'styled-components';

const StyledVideo = styled.video(
  ({ $bordered, $fullWidth }) => css`
    padding: 0;
    margin: 0.5em auto;
    display: block;
    max-width: 960px;

    ${$bordered &&
    css`
      border: var(--figure-border);
    `}

    ${$fullWidth &&
    css`
      border-radius: 8px;
      width: 100%;
    `}
  `
);

const Video = ({ children, withControls, fullWidth, bordered = true, ...props }) => {
  const attrs = withControls
    ? { controls: true }
    : {
        autoPlay: true,
        loop: true,
        muted: true,
        playsInline: true,
      };

  return (
    <StyledVideo {...attrs} $fullWidth={fullWidth} $bordered={bordered} {...props}>
      {children}
    </StyledVideo>
  );
};

export { Video };
