import { graphql, useStaticQuery } from 'gatsby';

export const usePrefixes = () => {
  const {
    site: { assetPrefix },
  } = useStaticQuery(graphql`
    query {
      site {
        assetPrefix
      }
    }
  `);

  return {
    assetPrefix,
  };
};
