import React, { createContext, useContext, useState } from 'react';

const LeadFormContext = createContext({
  endpoint: '/apps/api/v0.1/lead_form/',
});

export const useLeadFormContext = () => {
  const value = useContext(LeadFormContext);
  if (!value) {
    throw new Error('useLeadFormContext must be used within a LeadFormContextProvider.');
  }
  return value;
};

export const LeadFormContextProvider = ({ children, endpoint }) => {
  const [value] = useState({ endpoint });

  return <LeadFormContext.Provider value={value}>{children}</LeadFormContext.Provider>;
};
