import React from 'react';
import PropTypes from 'prop-types';
import { InputSpinner as Spinner } from '../spinner';

import { StyledButton, ButtonContent, ButtonSpinnerContainer } from './styles/Button.styles';
import { ButtonTypeEnum } from './ButtonTypeEnum';
import { ButtonSizeEnum } from './ButtonSizeEnum';
import { themes } from './themes';

const getButtonColors = (transparent, ghost, colors) => {
  if (transparent && colors.transparent) {
    return colors.transparent;
  }
  if (ghost) {
    return colors.ghost;
  }

  return colors.default;
};

export const Button = React.forwardRef(
  (
    {
      as = 'button',
      type = ButtonTypeEnum.DEFAULT,
      htmlType,
      customTheme,
      ghost = false,
      size = ButtonSizeEnum.NORMAL,
      block = false,
      long = false,
      children,
      isLoading = false,
      disabled = false,
      transparent = false,
      ...props
    },
    ref
  ) => {
    const buttonTheme = customTheme || {
      colors: getButtonColors(transparent, ghost, themes[type].colors),
    };

    return (
      <StyledButton
        as={as}
        type={htmlType}
        ref={ref}
        disabled={disabled}
        $buttonTheme={buttonTheme}
        $size={size}
        $block={block}
        $long={long}
        $isLoading={isLoading}
        {...props}
      >
        <ButtonContent>{children}</ButtonContent>
        {isLoading && (
          <ButtonSpinnerContainer>
            <Spinner />
          </ButtonSpinnerContainer>
        )}
      </StyledButton>
    );
  }
);

Button.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]),
  type: PropTypes.oneOf(Object.values(ButtonTypeEnum)),
  size: PropTypes.oneOf(Object.values(ButtonSizeEnum)),
  ghost: PropTypes.bool,
  block: PropTypes.bool,
  long: PropTypes.bool,
  htmlType: PropTypes.string,
  customTheme: PropTypes.object,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  transparent: PropTypes.bool,
};
