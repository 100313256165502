import { Code } from '@uc-common/code';
import { Link } from '~/src/components/Link';
import { Button, ButtonTypeEnum } from '@uc-common/button';
import { SectionWrapper } from '~/src/components/CommonStyles';
import { track } from '~/src/utils/tracker.js';
import * as React from 'react';
export default {
  Code,
  Link,
  Button,
  ButtonTypeEnum,
  SectionWrapper,
  track,
  React
};