import { Link } from '~/src/components/Link';
import { Code } from "@uc-common/code";
import { rekognitionExample, rekognitionExample2 } from "~/src/learning/code-examples/code-examples";
import * as React from 'react';
export default {
  Link,
  Code,
  rekognitionExample,
  rekognitionExample2,
  React
};