import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

export const GTM = ({ containerID }) => {
  useEffect(() => {
    if (!containerID) return;

    const noScript = document.createElement('noscript');
    noScript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=${containerID}"
              height="0"
              width="0"
              style="display:none;visibility:hidden">
      </iframe>`;
    document.body.prepend(noScript);
  }, [containerID]);

  return containerID ? (
    <Helmet>
      <script>
        {`
          {(function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
            "gtm.start": new Date().getTime(),
            event: "gtm.js",
          });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, "script", "dataLayer", '${containerID}')}
          `}
      </script>
    </Helmet>
  ) : null;
};
