import styled, { css } from 'styled-components';

export const CDNComparisonContainer = styled.div`
  margin-top: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CDNComparisonSelectors = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 18px;
  width: 100%;
`;

export const CDNComparisonSelectorLabel = styled.span`
  font-weight: 500;
  display: inline-block;
  font-size: 16px;
  margin-bottom: 10px;
`;

export const CDNComparisonContent = styled.div(
  ({ theme: { tablet } }) => css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    margin-top: 30px;

    ${tablet()} {
      grid-template-columns: 1fr 1fr;
      grid-gap: 50px;
    }
  `
);

export const CDNTransformationExample = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CDNTransformationExampleCDN = styled.h4`
  /* this overwrites default MDX tag styles */
  && {
    font-size: 22px;
    margin: 0 0 10px;
  }
`;

export const CDNTransformationExampleText = styled.p`
  && {
    font-size: 18px;
    text-align: center;
  }
`;
