import { setCookie } from '../use-cookie';
import { shouldBeTracked } from './shouldBeTracked';

/**
 * @param {string} href
 * @param {Function | null} onClick
 * @returns {(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void}
 */
export const useTrackSignup =
  (href = '', onClick = null) =>
  (e) => {
    if (shouldBeTracked(href)) {
      setCookie('signupFrom', window.location.href, {
        expires: 7,
        Secure: true,
        domain: '.uploadcare.com',
      });
    }

    onClick?.(e);
  };
