import { useCallback } from 'react';

export const SERVER_ERROR_TYPE = 'SERVER_ERROR_TYPE';
/**
 * @param {Function} [setError] Any error callback, e.g. setError from react-hook-form.
 * @param {Error} [error] Error object.
 * @param {string[]?} [ignoreFields] Fields to ignore.
 * @returns {{
 *   validationErrors: object;
 *   handleError: (error: Error) => void;
 * }}
 */

export const useServerValidation = (setError, error, ignoreFields = []) => {
  const handleServerValidation = useCallback(
    (errors) => {
      if (!setError) return;

      Object.keys(errors)
        .filter((field) => !ignoreFields.includes(field))
        .forEach((field) => {
          setError(field, {
            type: SERVER_ERROR_TYPE,
            message: errors[field][0],
          });
        });
    },
    [setError, ignoreFields]
  );

  const getValidationErrors = (error) => {
    const response = error?.response ?? {};
    return response.data?.errors;
  };

  const validationErrors = getValidationErrors(error);

  const handleError = useCallback(
    (error) => {
      const validationErrors = getValidationErrors(error);
      if (!validationErrors) {
        throw error;
      }

      handleServerValidation(validationErrors);
    },
    [handleServerValidation]
  );

  return { validationErrors, handleError };
};
