import React from 'react';

import { ULListContainer, ULListItem, ULListCheckmark } from './styles/ULList.styles';

export const ULList = ({
  className,
  items = [],
  children,
  icon = (
    <ULListCheckmark viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <use xlinkHref="#checkmark" />
    </ULListCheckmark>
  ),
}) => (
  <ULListContainer className={className}>
    {items.map((item) => (
      <ULListItem key={item}>
        {Boolean(icon) && icon}
        {item}
      </ULListItem>
    ))}
    {children}
  </ULListContainer>
);
