/* Vendors */
import React from 'react';
import styled from 'styled-components';

/* Components */
import { StorySection } from '../StorySection/StorySection';

/* Utils */

/* Types */

/* Styles */

const StyledSection = styled(StorySection)`
  margin: 48px 0px;
`;

/* Content */

/* Component */
export const StoryBreak = ({ visual = 'ecru-white', vertical = 48, ...props }) => (
  <StyledSection visual={visual} vertical={vertical} {...props} />
);

/* default props */
StoryBreak.defaultProps = {};
