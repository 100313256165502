/* Vendors */
import React from 'react';
import styled, { css } from 'styled-components';

/* Components */

/* Utils */
import { passProp } from '../../../utils/passProp';
import { withMedia } from '../../../utils/withMedia';

/* Types */

/* Styles */

const StyledPaddingBox = styled.div`
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  --padding-left: var(--padding-left-base);
  --padding-right: var(--padding-right-base);
  --padding-top: var(--padding-top-base);
  --padding-bottom: var(--padding-bottom-base);
  --padding-vertical: var(--padding-vertical-base);

  padding-top: var(--padding-top, var(--padding-vertical, var(--padding-vertical-base)));
  padding-bottom: var(--padding-bottom, var(--padding-vertical, var(--padding-vertical-base)));

  padding-left: var(--padding-left, var(--padding-horizontal, var(--padding-horizontal-base)));
  padding-right: var(--padding-right, var(--padding-horizontal, var(--padding-horizontal-base)));

  @media (${withMedia('--large-only')}) {
    --padding-left: calc(var(--padding-left-base) / 1.3);
    --padding-right: calc(var(--padding-right-base) / 1.3);

    --padding-vertical: calc(var(--padding-vertical-base) / 1.3);
    --padding-top: calc(var(--padding-top-base) / 1.3);
    --padding-bottom: calc(var(--padding-bottom-base) / 1.3);
  }

  @media (${withMedia('--medium')}) {
    --padding-left: calc(var(--padding-left-base) / 3);
    --padding-right: calc(var(--padding-right-base) / 3);

    --padding-vertical: calc(var(--padding-vertical-base) / 1.5);
    --padding-top: calc(var(--padding-top-base) / 1.5);
    --padding-bottom: calc(var(--padding-bottom-base) / 1.5);
  }

  @media (${withMedia('--small')}) {
    --padding-left: calc(var(--padding-left-base) / 4);
    --padding-right: calc(var(--padding-right-base) / 4);

    --padding-vertical: calc(var(--padding-vertical-base) / 2);
    --padding-top: calc(var(--padding-top-base) / 2);
    --padding-bottom: calc(var(--padding-bottom-base) / 2);
  }

  ${passProp('verticalBase', '--padding-vertical-base', {
    initial: 'var(--padding-vertical)',
  })}

  ${passProp('topBase', '--padding-top-base', {
    initial: 'var(--padding-top)',
  })}
  ${passProp('bottomBase', '--padding-bottom-base', {
    initial: 'var(--padding-bottom)',
  })}
  ${passProp('leftBase', '--padding-left-base', {
    initial: 'var(--padding-left)',
  })}
  ${passProp('rightBase', '--padding-right-base', {
    initial: 'var(--padding-right)',
  })}

  && {
    ${passProp('vertical', '--padding-vertical')}
    ${passProp('top', '--padding-top')}
    ${passProp('bottom', '--padding-bottom')}
    ${passProp('left', '--padding-left')}
    ${passProp('right', '--padding-right')}
  }
`;

/* PaddingBox */
export const PaddingBox = (props) => <StyledPaddingBox {...props} />;

/* default props */
PaddingBox.defaultProps = {
  vertical: '',
  horizontal: '',
  left: '',
  right: '',
  top: '',
  bottom: '',
};
