import React from 'react';
import useScript from 'react-script-hook';

import {
  CodePenContainer,
  CodePenContent,
  CodePenLoader,
  CodePenStatusContainer,
  CodePenErrorMessage,
} from './styles/CodePen.styles';

import { useMobileMode } from '../use-mobile-mode';

const SCRIPT_URL = 'https://static.codepen.io/assets/embed/ei.js';

export const CodePen = ({
  className,
  id,
  title,
  user = 'uploadcare',
  defaultTab = 'js,result',
  version = 2,
  themeId = 'light',
}) => {
  const [isLoading, error] = useScript({
    src: SCRIPT_URL,
  });

  const isMobile = useMobileMode();
  const isLoaded = !isLoading && !error;

  return (
    <CodePenContainer className={className}>
      <CodePenContent $isLoaded={isLoaded}>
        <div
          data-height="100%"
          data-theme-id={themeId}
          data-slug-hash={id}
          data-default-tab={defaultTab}
          data-user={user}
          data-embed-version={version}
          data-pen-title={title}
          data-preview={isMobile}
          className="codepen"
        >
          See the Pen{' '}
          <a
            href={`https://codepen.io/${user}/pen/${id}/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {title}
          </a>{' '}
          by{' '}
          <a href={`https://codepen.io/${user}`} target="_blank" rel="noopener noreferrer">
            @{user}
          </a>{' '}
          on{' '}
          <a href="https://codepen.io" target="_blank" rel="noopener noreferrer">
            CodePen
          </a>
          .
        </div>
      </CodePenContent>
      {!isLoaded && (
        <CodePenStatusContainer>
          {isLoading && <CodePenLoader />}
          {error && (
            <CodePenErrorMessage>Something went wrong, please try again later.</CodePenErrorMessage>
          )}
        </CodePenStatusContainer>
      )}
    </CodePenContainer>
  );
};
