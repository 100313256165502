/* Vendors */
import React from 'react';
import styled from 'styled-components';

/* Components */
import { Background } from '~/src/components/Background/Background';
import { Content } from '~/src/components/Content/Content';

/* Utils */
import { withMedia } from '~/src/utils/withMedia';

/* Types */

/* Styles */
const Banner = styled(Content)`
  text-align: center;
  margin: 0 auto 52px;

  --padding-horizontal-base: 120px;
  --padding-vertical-base: 0px;

  @media (${withMedia('--large-only')}) {
    margin-bottom: 52px;
  }

  @media (${withMedia('--medium')}) {
    margin-bottom: 40px;
  }

  @media (${withMedia('--small')}) {
    margin-bottom: 32px;
  }
`;

const Header = styled.h2`
  padding: 0;

  font-size: 36px;
  line-height: 40px;

  @media (max-width: 743px) {
    font-size: 28px;
    line-height: 32px;
  }

  @media (min-width: 1250px) {
    font-size: 46px;
    line-height: 48px;
  }
`;

const Description = styled.p`
  text-align: center;
  font-size: 18px;
  line-height: 1.56;
  margin-top: 32px;
  padding: 0px;
`;

const StyledContent = styled(Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

/* Component */

const getBanner = (title, description) =>
  (title || description) && (
    <Banner>
      {title && <Header>{title}</Header>}
      {description && <Description>{description}</Description>}
    </Banner>
  );

const defaultPaddings = {
  verticalBase: 80,
  horizontalBase: 120,
};

export const Section = ({
  className,
  position,
  title,
  description,
  visual,
  children,
  maxWidth,
  ...paddings
}) =>
  visual ? (
    <Background className={className} visual={visual}>
      <StyledContent position={position} {...defaultPaddings} maxWidth={maxWidth} {...paddings}>
        {getBanner(title, description)}
        {children}
      </StyledContent>
    </Background>
  ) : (
    <StyledContent
      {...defaultPaddings}
      {...paddings}
      maxWidth={maxWidth}
      className={className}
      position={position}
    >
      {getBanner(title, description)}
      {children}
    </StyledContent>
  );

/* default props */
Section.defaultProps = {};
