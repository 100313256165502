import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { useTrackSignup } from '@uc-common/use-track-signup';

import { StyledIcon, StyledLink } from './styles/Link.styles';

import { RoutesContext } from '../../contexts';
import { isInternalRoute } from '../../utils/isInternalRoute';
import { usePrefixes } from '../../hooks/usePrefixes';

/**
 * Returns the `a` element
 *
 * @param {string} className - CSS class (need here for styled components extending).
 * @param {string} to - Url, local or external.
 * @param {string} visual - Describe how the link should look like, one of these options: strong,
 *   chevron, rounded-primary, rounded-light, rounded-grey, rounded-dark.
 * @param {string} size - Size of the rounded link, one of these: xs, s, m, l.
 * @param {Node} children – Children.
 * @param {object} attributes – Other attributes.
 */
export const Link = React.forwardRef(
  ({ className, to, visual = null, size = 'm', children, onClick, ...attributes }, ref) => {
    let { assetPrefix } = usePrefixes();
    const handleClick = useTrackSignup(to, onClick);

    return (
      <RoutesContext.Consumer>
        {(routes) => {
          const isInternal = isInternalRoute(to, routes);

          if (isInternal) {
            return (
              <StyledLink
                as={GatsbyLink}
                ref={ref}
                className={className}
                to={to}
                onClick={handleClick}
                $visual={visual}
                $size={visual && size}
                {...attributes}
              >
                {children}
                {visual === 'chevron' && <StyledIcon />}
              </StyledLink>
            );
          }

          // remove protocol
          assetPrefix = assetPrefix?.replace(/.*:\/+/, '');
          if (assetPrefix && to?.indexOf(assetPrefix) !== -1) {
            // use split instead replace, because url doesn't contain assetPrefix like value from hook
            // https://github.com/gatsbyjs/gatsby/issues/21462
            // eslint-disable-next-line prefer-destructuring
            to = to.split(assetPrefix)[1];
          }

          return (
            <StyledLink
              ref={ref}
              className={className}
              href={to}
              onClick={handleClick}
              $visual={visual}
              $size={visual && size}
              {...attributes}
            >
              {children}
              {visual === 'chevron' && <StyledIcon />}
            </StyledLink>
          );
        }}
      </RoutesContext.Consumer>
    );
  }
);
