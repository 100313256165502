import React from 'react';
import styled from 'styled-components';

const YoutubeVideoIframe = styled.iframe`
  width: 100%;
  aspect-ratio: 16/9;
  margin: 10px 0;
`;

const YoutubeVideo = (props) => {
  return <YoutubeVideoIframe {...props} />;
};

export { YoutubeVideo };
