/* Vendors */
import React from 'react';
import styled from 'styled-components';
import { srcSet } from '../../../utils/responsive-images';

/* Navigations */

/* Utils */

/* Types */

/* Styles */

const AvatarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const AvatarImage = styled.img`
  height: 120px;
  width: 120px;
  border-radius: 50%;
  margin: 12px;
`;

/* Avatar */
export const Avatar = ({ className, uuid, alt }) => (
  <AvatarWrapper className={className}>
    <AvatarImage srcSet={srcSet(uuid, '/scale_crop/120x120/center/')} alt={alt} />
  </AvatarWrapper>
);

/* default props */
Avatar.defaultProps = {};
