import { Badge, BadgesBox } from '~/src/components/Badge';
import { Code } from "@uc-common/code";
import { Link } from '~/src/components/Link';
import * as React from 'react';
export default {
  Badge,
  BadgesBox,
  Code,
  Link,
  React
};