import React, { useEffect, forwardRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Prism from 'prismjs';
import 'prismjs/plugins/remove-initial-line-feed/prism-remove-initial-line-feed.min';
import 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace.min';
import 'prismjs/plugins/autolinker/prism-autolinker.min';
import 'prismjs/plugins/autolinker/prism-autolinker.css';
import { html_beautify as htmlBeautify, js_beautify as jsBeautify } from 'js-beautify';
import 'prismjs/plugins/autoloader/prism-autoloader.min';

import { StyledCopyButton, StyledCode, StyledContainer, StyledLabel } from './styles/Code.styles';
import { ReactComponent as CopyIcon } from './assets/copy.svg';
import { ReactComponent as CheckIcon } from './assets/check.svg';

import { ButtonSizeEnum, ButtonTypeEnum } from '../button';
import { StatusEnum, useCopyToClipboard } from '../use-copy-to-clipboard';
import { isBrowser } from '../utils';
import { useSyncedRef } from '../use-synced-ref';
import { getBeautifiedOptions, insertBlocksVersion } from './utils';

if (isBrowser()) {
  Prism.plugins.autoloader.languages_path =
    'https://cdnjs.cloudflare.com/ajax/libs/prism/1.25.0/components/';
}

const DEFAULT_COPY_BUTTON_PROPS = {
  isEnabled: true,
  type: ButtonTypeEnum.DARK,
  size: ButtonSizeEnum.EXTRA_SMALL,
  ghost: true,
};

export const Code = forwardRef(
  (
    {
      code,
      language = 'markup',
      className,
      autoLinker = true,
      label,
      beautifyOptions = {},
      copyButton = {},
      ...props
    },
    outerRef
  ) => {
    const [highlightedCode, setHighlightedCode] = useState(code);

    const copyButtonOptions = {
      ...DEFAULT_COPY_BUTTON_PROPS,
      ...copyButton,
    };
    const mergedBeatifyOptions = getBeautifiedOptions(beautifyOptions, code, language);

    const [, copyCode, status] = useCopyToClipboard(code, 500, copyButtonOptions.onClick);
    const codeRef = useSyncedRef(outerRef);

    const beatifyCode = useCallback(
      (processedCode) => {
        if (mergedBeatifyOptions.disableBeautify) return processedCode;

        const isJsLang = /javascript|js/.test(language);

        if (isJsLang) return jsBeautify(processedCode, mergedBeatifyOptions);

        const isMarkup = /markup|html|xml|svg|mathml|ssml|atom|rss/.test(language);

        if (isMarkup) return htmlBeautify(processedCode, mergedBeatifyOptions);

        return processedCode;
      },
      [language, mergedBeatifyOptions]
    );

    const processCode = useCallback(
      async (codeToProcess) => {
        const codeWithBlocks = await insertBlocksVersion(codeToProcess);
        return beatifyCode(codeWithBlocks);
      },
      [beatifyCode]
    );

    useEffect(() => {
      const processLinks = () => {
        const linksInCode = codeRef.current?.querySelectorAll('.url-link') ?? [];
        linksInCode.forEach((link) => {
          link.setAttribute('target', '_blank');
          link.setAttribute('rel', 'noopener noreferrer nofollow');
        });
      };

      // we're using setTimeout here because Prism is changing nodes in element even after the callback is called,
      // thus linksInCode might contain elements which are already deleted from DOM
      Prism.highlightElement(codeRef.current, false, () => setTimeout(processLinks, 120));
    }, [highlightedCode, codeRef]);

    useEffect(() => {
      processCode(code).then((processedCode) => setHighlightedCode(processedCode));
    }, [code, processCode]);

    return (
      <StyledContainer className={className}>
        {copyButtonOptions.isEnabled && (
          <StyledCopyButton {...copyButtonOptions} htmlType="button" onClick={copyCode}>
            {status === StatusEnum.SUCCESS ? <CheckIcon /> : <CopyIcon />}
          </StyledCopyButton>
        )}
        {label && <StyledLabel>{label}</StyledLabel>}
        <pre>
          <StyledCode
            $autoLinker={autoLinker}
            className={`language-${language}`}
            data-dependencies="bash"
            ref={codeRef}
            {...props}
          >
            {highlightedCode}
          </StyledCode>
        </pre>
      </StyledContainer>
    );
  }
);

Code.propTypes = {
  code: PropTypes.string.isRequired,
  language: PropTypes.string,
  copyButton: PropTypes.object,
  autoLinker: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  beautifyOptions: PropTypes.object,
};
