export const InputStateEnum = {
  IDLE: 'idle',
  ERROR: 'error',
};

export const InputSizeEnum = {
  EXTRA_SMALL: 'extra-small',
  SMALL: 'small',
  NORMAL: 'normal',
  LARGE: 'large',
};
