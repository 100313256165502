import { Badge, BadgesBox } from '~/src/components/Badge';
import { Video } from "~/src/mdx/components/Video";
import { Link } from '~/src/components/Link';
import * as React from 'react';
export default {
  Badge,
  BadgesBox,
  Video,
  Link,
  React
};