export const isInternalRoute = (url = '', routes = []) => {
  if (url.length === 0 || url[0] === '#') {
    return false;
  }

  const path = url
    .replace(/#.*$/, '') // remove location hash
    .replace(/\/?$/, '/'); // add trailing slash

  return routes.some((route) => route === path);
};
