module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-rollbar/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"62bb59e6b68c4cb79a2a61aa618f4220","captureUncaught":true,"captureUnhandledRejections":true,"ignoredMessages":["URI malformed"],"payload":{"environment":"staging","client":{"javascript":{"source_map_enabled":true,"guess_uncaught_frames":true}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"remarkPlugins":[[null,{"pathForBookmarks":"/home/runner/work/website/website/config/gatsby-plugins/refs.js"}],null,null,null],"rehypePlugins":[null,[null,{"behavior":"wrap"}]],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-prismjs","options":{"classPrefix":"lang-","showLineNumbers":false}}],"defaultLayouts":{"default":"/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx"},"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/runner/work/website/website","JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
