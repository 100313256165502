/* Vendors */
import React from 'react';
import styled from 'styled-components';
import { StorySection } from '../StorySection/StorySection';

/* Styles */

export const ParagraphSection = styled(StorySection)`
  align-items: start;
`;

const StyledParagraph = styled.p`
  font-family: var(--font-headings);
  font-size: 19px;
  line-height: 1.6;
  color: var(--color-dark);
  margin: 0;
  padding: 0;
  width: 100%;
`;

const StyledQuoteWrapper = styled.div`
  font-family: var(--font-headings);
  font-size: 19px;
  line-height: 1.52;
  color: var(--color-dark);
  margin: 0;
  padding: 0;
`;

/* Heading */
export const Paragraph = ({ className, children, quote }) => (
  <ParagraphSection className={className} vertical={12}>
    {quote ? (
      <StyledQuoteWrapper>{children}</StyledQuoteWrapper>
    ) : (
      <StyledParagraph>{children}</StyledParagraph>
    )}
  </ParagraphSection>
);

/* default props */
Paragraph.defaultProps = {};
