import { Widget } from '@uploadcare/react-widget/en';
import { Badge, BadgesBox } from '~/src/components/Badge';
import { Code } from "@uc-common/code";
import * as React from 'react';
export default {
  Widget,
  Badge,
  BadgesBox,
  Code,
  React
};