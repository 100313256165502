import styled, { css } from 'styled-components';
import { Link } from '../Link';

export const BigHeading = styled.h1(
  ({ theme: { tablet, laptop } }) => css`
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: -0.02em;

    ${tablet()} {
      font-size: 44px;
    }

    ${laptop()} {
      font-size: 54px;
    }
  `
);

export const BlueLink = styled(Link)`
  display: inline-block;
  font-weight: 500;
  font-size: inherit;
  line-height: inherit;
  text-decoration: none;
  height: max-content;
  width: max-content;
  color: #3771ff;
  margin: 0;
  cursor: pointer;

  &:hover {
    color: #0242df;
  }
`;

export const LinkArrowSimple = styled(BlueLink)`
  position: relative;
  transition: color 0.3s;

  &::after {
    display: inline-block;
    content: '→';
    margin-left: 0.1em;
    transition: transform 0.3s ease;
  }

  &:hover::after {
    transform: translateX(0.15em);
  }
`;

export const LinkWithArrow = styled(LinkArrowSimple)(
  ({ theme: { laptop } }) => css`
    font-size: 15px;
    line-height: 1.2;

    ${laptop()} {
      font-size: 16px;
    }
  `
);

export const SectionHeading = styled.h2(
  ({ theme: { tablet, laptop } }) => css`
    font-weight: 700;
    line-height: 1.3;
    letter-spacing: -0.02em;
    text-align: center;
    color: #000000;
    font-size: 32px;

    ${tablet()} {
      font-size: 44px;
    }

    ${laptop()} {
      font-size: 54px;
    }
  `
);

export const GradientSuperHeading = styled.span(
  ({ theme: { tablet, laptop } }) => css`
    display: inline-block;
    font-weight: 700;
    font-size: 12px;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    background: radial-gradient(141.01% 172.5% at 0% 0%, #b951be 0%, #9365fd 84.45%);
    margin-bottom: 15px;

    /* important property is required for preventing overriding this property by background */
    /* stylelint-disable-next-line */
    -webkit-background-clip: text !important;
    /* stylelint-disable-next-line */
    -webkit-text-fill-color: transparent;

    ${tablet()} {
      font-size: 14px;
      margin-bottom: 18px;
    }

    ${laptop()} {
      margin-bottom: 25px;
    }
  `
);

export const SectionSubtext = styled.p(
  ({ theme: { tablet, laptop } }) => css`
    color: #9ba5ad;
    font-weight: 500;
    text-align: center;
    line-height: 1.6;

    font-size: 16px;
    margin-top: 25px;

    ${tablet()} {
      font-size: 18px;
      margin-top: 30px;
    }

    ${laptop()} {
      font-size: 22px;
      margin-top: 35px;
    }
  `
);

export const Intro = styled.p(
  ({ theme: { tablet } }) => css`
    font-size: 18px;
    line-height: 1.5em;
    color: #596570;
    ${tablet()} {
      font-size: 20px;
      line-height: 1.6em;
    }
  `
);

export const Badge = styled.p`
  background-color: #edf0f2;
  padding: 1px 8px;
  border-radius: 5px;
  color: #596570;
  font-size: 13px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  display: inline-block;
  margin: 0;
`;

export const Notification = styled.p`
  background-color: #f6f7f8;
  padding: 14px 24px 14px 52px;
  margin-bottom: 1.2em;
  border-radius: 6px;
  color: #3a444e;
  font-size: 15px;
  line-height: 1.6em;
  font-weight: normal;

  position: relative;
  &::before {
    position: absolute;
    box-sizing: border-box;
    font-size: 12px;
    content: 'i';
    width: 18px;
    height: 18px;
    border: 1px solid;
    border-radius: 50%;
    left: 18px;
    top: 17px;
    line-height: 17px;
    text-align: center;
    opacity: 0.4;
  }
`;
