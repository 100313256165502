import urlJoin from 'url-join';

export const UC_CDN_BASE = 'https://ucarecdn.com/';

const getFileName = (url) => {
  const hasFileName = url.includes('.');
  if (!hasFileName) return '';
  return url.substring(1 + url.lastIndexOf('/')) || '';
};

export const getSrc = ({ uuid, src, options }) => {
  const operations = options
    ? `-/${Object.keys(options)
        .map((key) => `${key}/${options[key] || ''}`)
        .join('/-/')}/`
    : '/';

  if (uuid) {
    return urlJoin(UC_CDN_BASE, uuid, operations);
  }

  const cantUseCdnOperations = /\.gif|.svg|data:base|\/-\//.test(src);
  if (cantUseCdnOperations) {
    return src;
  }

  const url = new URL(src);
  const fileName = getFileName(url.pathname);
  if (fileName) {
    return urlJoin(src.replace(fileName, ''), operations, fileName);
  }

  return urlJoin(src, operations);
};
