export default [
  'adaris',
  'briaril',
  'calarel',
  'carris',
  'cynarel',
  'cyren',
  'elmet',
  'elonni',
  'enzana',
  'erydark',
  'fenralan',
  'ferand',
  'galen',
  'gavin',
  'gethriel',
  'iorill',
  'iothari',
  'iselva',
  'jadis',
  'lavra',
  'misiara',
  'namala',
  'nerion',
  'nethari',
  'pamaya',
  'sarnar',
  'sedis',
  'sewen',
  'sorahel',
  'sorlen',
  'tarian',
  'thellassan',
  'varriel',
  'varven',
  'vevera',
  'virkas',
  'yedis',
  'yllara',
  'zatvel',
  'zevcen',
];
