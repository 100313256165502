export const tracker =
  (namespace, defaultOptions) =>
  (event, props, options = {}) => {
    if (typeof window.analytics !== 'undefined') {
      window.analytics.track(`${namespace}: ${event}`, props, defaultOptions || options);
    }
  };

export const track = (event, options) => {
  window.analytics && window.analytics.track(event, options);
};
