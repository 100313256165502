/* Vendors */
import React from 'react';
import styled, { css } from 'styled-components';

/* Components */
import { Avatar } from '../Avatar/Avatar';

/* Utils */
import { withMedia } from '../../../utils/withMedia';

/* Types */

/* Styles */

export const StyledFigure = styled.figure`
  margin: 0 40px;
  padding: 0;
  text-align: inherit;
  color: var(--color-dark);

  /* align center if quote is big and not inlined */
  ${({ big, inline }) =>
    big &&
    !inline &&
    css`
      text-align: center;
    `}
`;

const StyledCaption = styled.figcaption`
  padding: 0;
  margin: 0;
  line-height: inherit;
  color: inherit;
  font-size: inherit;
  display: inline;

  &:before {
    content: '—';
    margin: 0px 6px;
  }
`;

export const StyledBlockquote = styled.blockquote`
  font-family: var(--font-headings);
  font-size: 21px;
  line-height: 1.52;
  margin: 0;
  padding: 0;
  text-align: inherit;
  display: inline;

  &:before {
    content: '“';
    margin-right: 2px;

    @media (${withMedia('--small')}) {
      margin-left: 0;
    }
  }

  &:after {
    content: '”';
    margin-left: 2px;
  }

  /* big quotes */
  ${({ big }) =>
    big &&
    css`
      font-size: 24px;
      line-height: 1.5;
      font-weight: 300;

      @media (${withMedia('--small')}) {
        font-size: 20px;
      }
    `}
`;

export const StyledAvatarAuthor = styled.figcaption`
  font-family: var(--font-headings);
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  margin: 12px 0px 32px 0px;
  text-align: center;
  padding: 0px;
`;

/* Quote */
export const Quote = ({ className, children, author, avatar, inline, big }) => (
  <StyledFigure inline={inline} big={big}>
    {avatar && <Avatar uuid={avatar} alt={author} />}
    {author && <StyledAvatarAuthor>{author}</StyledAvatarAuthor>}
    <StyledBlockquote className={className} inline={inline} big={big}>
      {children}
    </StyledBlockquote>
    {author && inline && <StyledCaption>{author}</StyledCaption>}
  </StyledFigure>
);

/* default props */
Quote.defaultProps = {};
