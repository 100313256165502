export const CDN_ENUM = {
  CLOUDINARY: 'cloudinary',
  FILESTACK: 'filestack',
};

export const CDNS_TO_COMPARE = [
  {
    value: CDN_ENUM.CLOUDINARY,
    label: 'Cloudinary',
    exampleImage: 'https://res.cloudinary.com/demo/image/upload/<>/sheep.jpg',
    ucExampleImageUUID: '6aeebfdc-2a34-4913-9037-7d6164f5159b',
  },
  {
    value: CDN_ENUM.FILESTACK,
    label: 'Filestack',
    exampleImage: 'https://process.filestackapi.com/<>/2h25ZGRHTfmQ2DBEt3yR',
    ucExampleImageUUID: 'b85bbf3a-393d-4217-b23e-02f0329a017d',
  },
];

export const CLOUDINARY_TRANSFORMATIONS = [
  {
    value: 'angle',
    label: 'a (angle)',
    operation: 'c_limit,w_400/a_90',
    ucOperation: '-/resize/400x/-/rotate/270/',
  },
  {
    value: 'crop',
    label: 'c (crop/resize)',
    operation: 'c_crop,h_400,w_400',
    ucOperation: '-/crop/400x400/center/',
  },
  {
    value: 'quality',
    label: 'q (quality)',
    operation: 'c_limit,w_400/q_auto:best',
    ucOperation: '-/resize/400x/-/quality/best/',
  },
  {
    value: 'grayscale',
    label: 'e (effect) grayscale',
    operation: 'c_limit,w_400/e_grayscale',
    ucOperation: '-/resize/400x/-/grayscale/',
  },
];

export const FILESTACK_TRANSFORMATIONS = [
  {
    value: 'resize',
    label: 'Resize',
    operation: 'resize=width:400',
    ucOperation: '-/resize/400x/',
  },
  {
    value: 'crop',
    label: 'Crop',
    operation: 'resize=width:400,height:400,fit:crop',
    ucOperation: '-/scale_crop/400x400/center/',
  },
  {
    value: 'flip',
    label: 'Flip',
    operation: 'resize=width:400/flip',
    ucOperation: '-/resize/400x/-/flip/',
  },
  {
    value: 'flop',
    label: 'Flop',
    operation: 'resize=width:400/flop',
    ucOperation: '-/resize/400x/-/mirror/',
  },
];

export const CDN_TRANSFORMATIONS = new Map([
  [CDN_ENUM.CLOUDINARY, CLOUDINARY_TRANSFORMATIONS],
  [CDN_ENUM.FILESTACK, FILESTACK_TRANSFORMATIONS],
]);
