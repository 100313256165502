import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { components } from 'react-select';

import {
  StyledIndicator,
  StyledSelect,
  StyledSelectContainer,
  StyledSelectControl,
} from './styles/styled-components';

import { SELECT_CLASSNAME_PREFIX } from './constants';
import { InputSelectSizeEnum, InputSelectStateEnum } from './enums';
import { INPUT_SELECT_THEME_DEFAULT } from './styles/themes';
import { getOptionByValue } from './utils';

const { Option } = components;

const IconOption = ({ data, ...props }) => (
  <Option data={data} {...props}>
    {data.icon?.(data)}
    {data.label}
  </Option>
);

const SelectControl = ({ selectProps, ...props }) => {
  const { ref, id, name, value = '', onChange } = selectProps.commonProps;
  const { size, themeDefinition } = selectProps;

  return (
    <>
      <input id={id} ref={ref} name={name} value={value} onChange={onChange} type="hidden" />
      <StyledSelectControl
        size={size}
        themeDefinition={themeDefinition}
        selectProps={selectProps}
        {...props}
      />
    </>
  );
};

export const InputSelect = React.forwardRef(
  (
    {
      id,
      name,
      size = InputSelectSizeEnum.NORMAL,
      themeDefinition = INPUT_SELECT_THEME_DEFAULT,
      hasError = false,
      onChange,
      components,
      options = [],
      value,
      isSearchable = false,
      isDisabled = false,
      defaultValue = '',
      ...props
    },
    ref
  ) => {
    const [currentValue, setCurrentValue] = useState(defaultValue);

    const theme =
      themeDefinition[hasError ? InputSelectStateEnum.ERROR : InputSelectStateEnum.IDLE];

    useEffect(() => {
      // If value is undefined we should reset selected option to default one.
      // In other words we allow to reset select field to its default state programmatically.
      // For example, by calling resetField from react-form-hook lib.
      setCurrentValue(value === undefined ? defaultValue : value);
    }, [defaultValue, value]);

    const handleOnChange = useCallback(
      (option) => {
        if (value === undefined) setCurrentValue(option.value);

        onChange && onChange(option.value);
      },
      [onChange, value]
    );

    const DropdownIndicator = useCallback(() => <StyledIndicator theme={theme} />, [theme]);

    return (
      <div data-analytics={props['data-analytics']} data-cy={props['data-cy']}>
        <StyledSelect
          classNamePrefix={SELECT_CLASSNAME_PREFIX}
          theme={theme}
          size={size}
          components={{
            DropdownIndicator,
            IndicatorSeparator: null,
            Option: IconOption,
            Control: SelectControl,
            SelectContainer: StyledSelectContainer,
            ...components,
          }}
          value={getOptionByValue(options, currentValue) || ''}
          onChange={handleOnChange}
          options={options}
          commonProps={{ id, name, ref, value: currentValue, onChange }}
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          {...props}
        />
      </div>
    );
  }
);

InputSelect.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  themeDefinition: PropTypes.object,
  hasError: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(InputSelectSizeEnum)),
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  components: PropTypes.object,
  options: PropTypes.array,
  isDisabled: PropTypes.bool,
  isSearchable: PropTypes.bool,
  'data-analytics': PropTypes.string,
  'data-cy': PropTypes.string,
};
