import styled from 'styled-components';

// eslint-disable-next-line import/no-extraneous-dependencies
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
  typography,
} from 'styled-system';

// TODO: remove all this stuff and use rebass
export const Box = styled.div(
  {
    boxSizing: 'border-box',
    minWidth: 0,
  },
  space,
  layout,
  flexbox
);

export const Flex = styled.div(
  {
    display: 'flex',
    boxSizing: 'border-box',
    minWidth: 0,
  },
  space,
  layout,
  flexbox
);

export const Grid = styled.div(
  {
    display: 'grid',
    boxSizing: 'border-box',
    minWidth: 0,
  },
  space,
  layout,
  flexbox,
  grid
);

export const Text = styled.div(typography, color);
export const Position = styled.div(position);
export const Color = styled.div(color);
export const Background = styled.div(background);
export const Border = styled.div(border);
