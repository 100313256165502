import { InputStateEnum, INPUT_THEME_DEFAULT, INPUT_THEME_DARK } from '../../input';
import { InputSelectStateEnum } from '../enums';

export const INPUT_SELECT_THEME_DEFAULT = {
  [InputSelectStateEnum.IDLE]: {
    ...INPUT_THEME_DEFAULT[InputStateEnum.IDLE],
    selectedOptionBackground: '#52b0ff',
  },
  [InputSelectStateEnum.ERROR]: {
    ...INPUT_THEME_DEFAULT[InputStateEnum.ERROR],
    selectedOptionBackground: '#52b0ff',
  },
};

export const INPUT_SELECT_THEME_DARK = {
  [InputSelectStateEnum.IDLE]: {
    ...INPUT_THEME_DARK[InputStateEnum.IDLE],
    selectedOptionBackground: '#3771FF',
    focusedOptionBackground: '#3771FF',
    activeOptionBackground: '#0242DF',
    defaultMenuColor: '#2B2A29',
    menuBorder: '1px solid #4D565F',
  },
  [InputSelectStateEnum.ERROR]: {
    ...INPUT_THEME_DARK[InputStateEnum.ERROR],
    selectedOptionBackground: '#3771FF',
    focusedOptionBackground: '#3771FF',
    activeOptionBackground: '#0242DF',
    defaultMenuColor: '#2B2A29',
    menuBorder: '1px solid #4D565F',
  },
};
