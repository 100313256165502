/* Vendors */
import React from 'react';
import styled from 'styled-components';
import { StorySection } from '../StorySection/StorySection';

/* Navigations */

/* Utils */
import { withMedia } from '../../../utils/withMedia';

/* Types */

/* Styles */

const StyledHeading = styled.h1`
  font-family: var(--font-headings);
  font-size: 56px;
  font-weight: 500;
  line-height: 1.29;
  text-align: center;
  margin-bottom: 24px;
  width: 980px;

  @media (${withMedia('--medium')}) {
    width: 100%;
  }

  @media (${withMedia('--small')}) {
    font-size: 32px;
    width: 100%;
  }
`;

/* Heading */
export const Heading = ({ as, className, children }) => (
  <StorySection className={className} maxWidth={980}>
    <StyledHeading as={as}>{children}</StyledHeading>
  </StorySection>
);

/* default props */
Heading.defaultProps = {};
