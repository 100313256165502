/* Vendors */
import React from 'react';
import { Section } from '../Section/Section';

/* Navigations */

/* Utils */

/* Types */

/* Styles */

/* Section */

const defaultProps = {
  maxWidth: 960,
  verticalBase: 0,
};

export const StorySection = (props) => <Section {...defaultProps} {...props} />;

/* default props */
StorySection.defaultProps = {};
