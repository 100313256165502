/* Vendors */
import React from 'react';
import styled, { css } from 'styled-components';

/* Components */
import { StorySection } from '../StorySection/StorySection';

/* Utils */
import { withMedia } from '../../../utils/withMedia';
import { srcSet, imgFallback } from '../../../utils/responsive-images';

/* Types */

/* Styles */

const gridStyles = {
  right: css`
    grid-template-columns: minmax(580px, 1fr) minmax(140px, 1fr);
    grid-gap: 20px 40px;

    @media (${withMedia('--small')}) {
      grid-template-columns: initial;
      grid-template-rows: auto auto;
    }
  `,
  bottom: css`
    grid-template-rows: auto;
    grid-gap: 24px;
  `,
};

const getSectionStyle = (wide) =>
  wide
    ? css`
        max-width: 1000px;
      `
    : css`
        max-width: 760px;
      `;

const StyledStorySection = styled(StorySection)`
  ${({ wide }) => getSectionStyle(wide)};
`;

const PictureWrapper = styled.div`
  img {
    width: 100%;
  }
`;

const StyledFigure = styled.figure`
  width: 100%;
  margin: 0;
  padding: 0;

  display: grid;
  ${({ captionPosition }) => gridStyles[captionPosition]};
`;

const Caption = styled.figcaption`
  font-family: var(--font-headings);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
  letter-spacing: 0.6px;
  color: var(--color-grey);
  height: 100%;
  text-align: left;
  margin: 0;
  padding: 0;
  width: 100%;
`;

/* Content */

/* Component */
const Picture = ({ uuid, wide, captionPosition, modifiers, alt }) => {
  const makeModifiers = (previewBox) =>
    `${modifiers ? `${modifiers}-` : ''}/preview/${previewBox.join('x')}/`;

  const makeSrcSet = (previewBox) => srcSet(uuid, makeModifiers(previewBox));
  const makeFallback = (previewBox) => imgFallback(uuid, makeModifiers(previewBox));

  const previewBox = () => {
    if (wide) {
      return [1000, 600];
    }

    if (captionPosition === 'right') {
      return [600, 500];
    }

    return [760, 500];
  };

  return (
    <PictureWrapper>
      <picture>
        <source srcSet={makeSrcSet([400, 300])} media="(max-width: 479px)" />
        <source srcSet={makeSrcSet([700, 600])} media="(max-width: 743px)" />
        <source srcSet={makeSrcSet(previewBox())} />
        <img src={makeFallback(previewBox())} alt={alt} />
      </picture>
    </PictureWrapper>
  );
};

export const StoryFigure = ({
  className,
  uuid,
  caption,
  captionPosition,
  wide,
  modifiers,
  alt,
}) => (
  <StyledStorySection className={className} vertical={40} wide={wide}>
    <StyledFigure captionPosition={captionPosition}>
      <Picture
        uuid={uuid}
        wide={wide}
        captionPosition={captionPosition}
        alt={alt}
        modifiers={modifiers}
      />
      {caption && (
        <StorySection horizontal="0">
          <Caption>{caption}</Caption>
        </StorySection>
      )}
    </StyledFigure>
  </StyledStorySection>
);

/* default props */
StoryFigure.defaultProps = {};
