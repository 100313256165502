import React, { lazy } from 'react';
import { useMount } from '../use-mount';

const ClientSuspense = ({ children, fallback }) => {
  const isMounted = useMount();

  if (!isMounted) {
    return fallback;
  }

  return <React.Suspense fallback={fallback}>{children}</React.Suspense>;
};

export { lazy, ClientSuspense };
