import styled, { css } from 'styled-components';

import { Container as Wrapper } from '@uc-common/container';
import { ReactComponent as CheckSvg } from '@static/icons/check-icon.svg';

export const SectionWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CheckIcon = styled(CheckSvg)(
  ({ $background = '#04B2FF', $color = '#ffffff' }) => css`
    fill: ${$background};
    width: 18px;
    height: 18px;
    margin-right: 12px;
    flex-shrink: 0;
    color: ${$color};
  `
);

export const hideScrollbarStyles = css`
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
