import { Heading } from '~/src/mdx/components/Heading/Heading';
import { StoryCompanySection } from '~/src/mdx/components/StoryCompanySection/StoryCompanySection';
import { Button } from '~/src/mdx/components/Button/Button';
import { StoryBreak } from '~/src/mdx/components/StoryBreak/StoryBreak';
import { Quote } from '~/src/mdx/components/Quote/Quote';
import { StoryFigure } from '~/src/mdx/components/StoryFigure/StoryFigure';
import * as React from 'react';
export default {
  Heading,
  StoryCompanySection,
  Button,
  StoryBreak,
  Quote,
  StoryFigure,
  React
};