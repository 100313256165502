import React from 'react';
import styled from 'styled-components';
import Input from '../input';
import { InputSpinner } from '../spinner';
import { ClientSuspense, lazy } from '../client-suspense';

const LazyComponent = lazy(() =>
  import('./InputSelect').then((module) => ({ default: module.InputSelect }))
);

const Loader = styled(Input)`
  pointer-events: none;
  color: #b5b5b5;
`;

export const LazyInputSelect = ({ size, themeDefinition, ...props }) => (
  <ClientSuspense
    fallback={
      <Loader forwardedAs="div" size={size} themeDefinition={themeDefinition}>
        <InputSpinner />
      </Loader>
    }
  >
    <LazyComponent size={size} themeDefinition={themeDefinition} {...props} />
  </ClientSuspense>
);
