import styled, { css } from 'styled-components';
import { InputSpinner } from '../../spinner';

export const CodePenContainer = styled.div(
  ({ theme: { tablet } }) => css`
    position: relative;
    width: 100%;
    height: 300px;
    box-sizing: border-box;
    margin: 16px 0 30px;

    ${tablet()} {
      height: 500px;
    }
  `
);

export const CodePenContent = styled.div(
  ({ $isLoaded = false }) => css`
    display: grid;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    transform: scale(0.97);
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
    transition-delay: 1s;

    ${$isLoaded &&
    css`
      transform: scale(1);
      opacity: 1;
      pointer-events: auto;
    `}
  `
);

export const CodePenStatusContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CodePenLoader = styled(InputSpinner)`
  width: 3em;
  height: 3em;
`;

export const CodePenErrorMessage = styled.p`
  font-size: 16px;
  text-align: center;
  color: #e4496f;
`;
