/* Vendors */
import React from 'react';
import styled, { css } from 'styled-components';

/* Components */

/* Utils */

/* Types */

/* Styles */
const getColor = ({ visual }) => css`
  background-color: ${visual.startsWith('#') ? visual : `var(--color-${visual})`};
`;

const Component = styled.div`
  ${getColor};
`;

/* Component */
export const Background = ({ className, visual = 'light', children }) => (
  <Component className={className} visual={visual}>
    {children}
  </Component>
);

/* default props */
Background.defaultProps = { visual: 'light' };
