import styled, { css, keyframes } from 'styled-components';

import { ButtonSizeEnum } from '../ButtonSizeEnum';

const StateStyles = ({ $buttonTheme }) => css`
  &,
  &:visited {
    background-color: ${$buttonTheme.colors.defaultBackgroundColor};
    color: ${$buttonTheme.colors.defaultColor};

    ${$buttonTheme.colors.defaultBorderColor &&
    css`
      border: 1px solid ${$buttonTheme.colors.defaultBorderColor};
    `};
  }

  &:hover,
  &:visited:hover {
    box-shadow: none;
    background-color: ${$buttonTheme.colors.hoverBackgroundColor};
    color: ${$buttonTheme.colors.hoverColor};

    ${$buttonTheme.colors.hoverBorderColor &&
    css`
      border: 1px solid ${$buttonTheme.colors.hoverBorderColor};
    `};
  }

  &:focus,
  &:visited:focus {
    box-shadow: none;
    outline: 0;
    background-color: ${$buttonTheme.colors.focusBackgroundColor};
    color: ${$buttonTheme.colors.focusColor};

    ${$buttonTheme.colors.focusBorderColor &&
    css`
      border: 1px solid ${$buttonTheme.colors.focusBorderColor};
    `};
  }

  &:active,
  &:visited:active {
    background-color: ${$buttonTheme.colors.activeBackgroundColor};
    color: ${$buttonTheme.colors.activeColor};
  }

  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active {
      background-color: ${$buttonTheme.colors.disabledBackgroundColor};
      color: ${$buttonTheme.colors.disabledColor};
      pointer-events: none;

      ${$buttonTheme.colors.disabledBorderColor &&
      css`
        border: 1px solid ${$buttonTheme.colors.disabledBorderColor};
      `};
    }
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px ${$buttonTheme.colors.focusOutlineColor || '#b7ccff'};
  }
`;

export const ButtonContent = styled.span`
  transition: opacity 0.2s ease, transform 0.2s ease;
`;

const showSpinner = keyframes`
  to {
    opacity: 1;
  }
`;

export const ButtonSpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: max-content;
  height: max-content;
  opacity: 0;
  animation: ${showSpinner} 0.2s ease-in forwards 0.65s;
`;

export const StyledButton = styled.button(
  ({ $size, $long, $block, $isLoading }) => css`
    position: relative;
    font-family: inherit;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    white-space: nowrap;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;
    border: none;
    border-radius: 5px;
    box-shadow: 0 0 0 0 transparent;
    cursor: pointer;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out,
      color 0.2s ease-in-out, border-color 0.2s ease-in-out, opacity 0.2s ease-in-out;

    ${StateStyles}

    ${() => {
      switch ($size) {
        case ButtonSizeEnum.EXTRA_SMALL:
          return css`
            height: 32px;
            padding: 0 12px;
            border-radius: 5px;
          `;
        case ButtonSizeEnum.SMALL:
          return css`
            height: 38px;
            padding: 0 14px;
          `;
        case ButtonSizeEnum.NORMAL:
          return css`
            height: 44px;
            padding: 0 20px;
            font-size: 14px;
          `;
        case ButtonSizeEnum.LARGE:
          return css`
            height: 52px;
            padding: 0 25px;
            font-size: 14px;
          `;
        default:
          return '';
      }
    }}

  ${$long &&
    css`
      padding: 0 28px;
    `}

  ${$block &&
    css`
      width: 100%;
    `}
    
    ${$isLoading &&
    css`
      pointer-events: none;

      ${ButtonContent} {
        opacity: 0;
        transform: scale(0.9);
        transition-delay: 0.45s;
      }
    `}
  `
);
