// we export large code examples from here, because gatsby-plugin-mdx crashes,
// while trying process them in mdx
export const uploadFile = `
import { uploadFile } from "@uploadcare/upload-client";

const data = new Blob(["This is example file"], { type: "text/plain" });
const file = await uploadFile(data, {
publicKey: "YOUR_PUBLIC_KEY",
fileName: "example.txt",
});

console.log(file);
`;

export const vanillaJSUpload = `
<div>
  <input type="file"  accept="image/*" name="image" id="file"  onchange="loadFile(event)" style="display: none;">
  <label for="file" style="cursor: pointer;">Upload Image</label>
  <img id="output" width="200" />
</div>

<script>
var loadFile = function(event) {
	var image = document.getElementById('output');
	image.src = URL.createObjectURL(event.target.files[0]);
};
</script>
`;

export const uploadcareUploader = `
<script>
  UPLOADCARE_PUBLIC_KEY = 'demopublickey';
</script>
<script src="https://ucarecdn.com/libs/widget/3.x/uploadcare.full.min.js"></script>
`;

export const smartCompressionExample = `
https://ucarecdn.com/
9a30b55f-c47f-498f-af6b-79f316e9e0da/
-/format/auto/
-/quality/smart/
`;
export const qualitySmartInline = `
/quality/smart/
`;
export const formatAutoExample = `
https://ucarecdn.com/
9a30b55f-c47f-498f-af6b-79f316e9e0da/
-/format/auto/
-/quality/smart/
`;
export const scaleCropExample = `
https://ucarecdn.com/
58b1b3ac-8cc4-4375-a089-4267466c1741/
-/scale_crop/934x550/center/
`;

export const sharpenExample = `
https://ucarecdn.com/
8784fbb1-e860-41e6-8968-142ad3017230/
-/sharp/
`;

export const smartCropExample = `
https://ucarecdn.com/
965da21d-7f8f-4579-8b42-7ef6d3819fff/
-/scale_crop/550x550/smart/
`;
export const proportionalDownscaleExample = `
https://ucarecdn.com/
743a8040-04c4-46ef-bd45-b3ef864da6e6/
-/preview/934x550/
`;
export const watermarkExample = `
https://ucarecdn.com/
9592ba3a-8786-49d5-af7d-41a02ae27174/
-/overlay/c418d8e4-8d3b-447c-aa18-095670cf3ccb/30px40p/90p,10p/20p/
`;
export const faceCropExample = `
https://ucarecdn.com/
d8ebbd7a-5790-4afc-86f1-60d8e2f60011/
-/crop/face/200px200p/
`;
export const faceCropExample2 = `
https://ucarecdn.com/
d8ebbd7a-5790-4afc-86f1-60d8e2f60011/
-/crop/face/200px200p/
-/scale_crop/550x550/center/
`;
export const enhanceExample = `
https://ucarecdn.com/
153d750a-049f-44dc-8372-853f49320bad/
-/preview/
-/enhance/
`;
export const filterExample = `
https://ucarecdn.com/
6c9493a7-d967-4e40-b0cc-2f1a9df83018/
-/preview/
-/filter/iorill/
`;
export const monochromeExample = `
https://ucarecdn.com/
2d369441-2228-4199-9f06-0939e29038b5/
-/preview/
-/grayscale/
`;
export const blurFacesExample = `
https://ucarecdn.com/
91006c1f-6a6e-46cc-b3fe-b5803a6add92/
-/preview/
-/blur_region/faces/
`;
export const colorsExample = `
https://ucarecdn.com/
3f8041f1-bd2b-4b33-b60b-1b6e297b1b66/
-/preview/
-/main_colors/5/
`;
export const colorsExample2 = `
{
  …
  "main_colors": [
  [6, 42, 88],
  [101, 80, 96],
  [244, 110, 128],
  [234, 206, 206],
  [91, 162, 206]
  ]
  }
`;
export const rekognitionExample = `
  curl -H "Accept: application/vnd.uploadcare-v0.6+json" \
  -H "Authorization: Uploadcare.Simple publickey:secretkey" \
  "https://api.uploadcare.com/files/:uuid/?add_fields=rekognition_info"
`;
export const rekognitionExample2 = `
{
  ...
  "rekognition_info":
  {
  "Building": 0.63291,
  "House": 0.63291,
  "Housing": 0.63291,
  "Architecture": 0.63008,
  "Outdoors": 0.55667,
  "Cottage": 0.63291,
  "Pond": 0.55667,
  "Castle": 0.63008,
  "Moat": 0.63008,
  "Fort": 0.63008
  }
  }
`;
export const rotateExample = `
https://ucarecdn.com/
fed0db08-4d60-4ecd-a0a5-f2b6e45a5740/
-/autorotate/yes/
`;
export const rotateExample2 = `
https://ucarecdn.com/
fed0db08-4d60-4ecd-a0a5-f2b6e45a5740/
-/preview/
-/rotate/90/
`;
export const flipExample = `
https://ucarecdn.com/
b39cee6a-de62-40af-b2bc-8783152c18da/
-/preview/
-/flip/
`;
export const mirrorExample = `
https://ucarecdn.com/
b39cee6a-de62-40af-b2bc-8783152c18da/
-/preview/
-/mirror/
`;
export const zoomExample = `
https://ucarecdn.com/
022a66d1-6dfe-4416-81a1-72d70cd17ff4/
-/preview/
-/zoom_objects/80/
`;
