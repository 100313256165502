import styled, { css } from 'styled-components';

import { withMedia } from '~/src/utils/withMedia';
import { THEME } from '~/src/constants/theme';
import { ReactComponent as Chevron } from '../assets/chevron.svg';

const RoundedLink = css`
  --vertical-padding: 12px;
  --horizontal-padding: 25px;

  display: inline-block;
  padding: var(--vertical-padding) var(--horizontal-padding);
  border-radius: 100px;
  text-decoration: none;
  font-family: var(--font-headings);
  font-size: 15px;
  line-height: 16px;
  font-weight: 500;
  letter-spacing: 0.6px;
  white-space: nowrap;

  &,
  &:hover,
  &:focus,
  &:active,
  &:visited,
  &:visited:hover,
  &:visited:focus,
  &:visited:active {
    color: var(--color-dark);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px var(--focus-color);
  }
`;

export const StyledIcon = styled(Chevron)`
  display: inline-block;
  margin-left: 8px;
  vertical-align: middle;

  @media (max-width: 1279px) {
    height: 8px;
  }
`;

/**
 * Visual style for link.
 *
 * @param {string} visual
 */
const getLinkVisualStyle = ({ $visual }) => {
  switch ($visual) {
    case 'strong':
      return css`
        font-family: var(--font-headings);
        text-decoration: none;

        &,
        &:active,
        &:visited,
        &:visited:active {
          color: inherit;
        }

        &:hover,
        &:focus,
        &:visited:hover,
        &:visited:focus {
          color: var(--color-grey);
        }
      `;
    case 'chevron':
      return css`
        font-family: var(--font-headings);
        text-decoration: none;

        &,
        &:active,
        &:visited,
        &:visited:active {
          color: inherit;
        }

        &:hover,
        &:focus,
        &:visited:hover,
        &:visited:focus {
          color: var(--color-grey);
        }
      `;
    case 'strong-blue':
      return css`
        font-family: var(--font-headings);
        text-decoration: none;
        font-weight: 500;

        &,
        &:active,
        &:visited,
        &:visited:active {
          color: #0047ff;
        }

        &:hover,
        &:focus,
        &:visited:hover,
        &:visited:focus {
          color: #000;
        }
      `;
    case 'strong-gray':
      return css`
        font-family: var(--font-headings);
        text-decoration: none;

        &,
        &:visited {
          color: #808080;
        }

        &:active,
        &:visited:active {
          color: #003ac9;
        }

        &:hover,
        &:focus,
        &:visited:hover,
        &:visited:focus {
          color: #0e54ff;
        }
      `;
    case 'rounded-primary':
      return css`
        ${RoundedLink};
        &,
        &:visited {
          background: var(--color-yellow);
        }

        &:hover,
        &:focus {
          background: var(--color-light-yellow);
        }

        &:active {
          background: var(--color-dark-yellow);
        }
      `;
    case 'rounded-light':
      return css`
        ${RoundedLink};
        &,
        &:visited {
          background: var(--color-light);
        }

        &:hover,
        &:focus {
          background: var(--color-lighten);
        }

        &:active {
          background: var(--color-lighten-grey);
        }
      `;
    case 'rounded-grey':
      return css`
        ${RoundedLink};
        &,
        &:visited {
          background: var(--color-light-grey);
        }

        &:hover,
        &:focus {
          background: var(--color-lighten);
        }

        &:active {
          background: var(--color-lighten-grey);
        }
      `;
    case 'rounded-dark':
      return css`
        ${RoundedLink};
        &,
        &:hover,
        &:focus,
        &:active,
        &:visited,
        &:visited:hover,
        &:visited:focus,
        &:visited:active {
          color: var(--color-light);
        }

        &,
        &:visited {
          background: var(--color-dark);
        }

        &:hover,
        &:focus {
          background: var(--color-dark-grey);
        }

        &:active {
          background: var(--color-darken-grey);
        }
      `;
    case THEME.LIGHT_BLUE:
      return css`
        --color-light-blue: rgb(35, 141, 230);
        --color-light-blue-hovered: rgb(82, 176, 255);

        color: var(--color-light-blue);

        &:hover,
        &:focus {
          color: var(--color-light-blue-hovered);
        }
      `;
    default:
      return '';
  }
};

/**
 * Get link size.
 *
 * @param {string} size
 */
const getLinkSizeStyle = ({ $size = 'm' }) => {
  switch ($size) {
    case 's':
      return css`
        --vertical-padding: 10px;
        --horizontal-padding: 16px;

        font-size: 14px;
        line-height: 16px;
        letter-spacing: normal;
      `;
    case 'm':
      return css`
        --vertical-padding: 12px;
        --horizontal-padding: 25px;

        font-size: 15px;
        line-height: 16px;
        letter-spacing: 0.6px;
      `;
    case 'l':
      return css`
        --vertical-padding: 16px;
        --horizontal-padding: 32px;

        font-size: 24px;
        line-height: 24px;
        letter-spacing: 1.2px;

        @media (${withMedia('--small')}) {
          --vertical-padding: 12px;
          --horizontal-padding: 25px;

          font-size: 15px;
          line-height: 16px;
          letter-spacing: 0.6px;
        }
      `;
    default:
      return '';
  }
};

export const StyledLink = styled.a`
  ${getLinkVisualStyle} ${getLinkSizeStyle};
`;
