export const passProp =
  (prop, cssProperty, { initial, modify } = {}) =>
  (props) => {
    if (typeof props[prop] !== 'undefined' && props[prop] !== '') {
      const isNumber = Number.isFinite(parseInt(props[prop], 10));
      const value = modify ? modify(props[prop]) : props[prop];
      const valuePostfix = isNumber ? 'px' : '';

      return `${cssProperty}: ${value}${valuePostfix};`;
    }
    if (typeof initial !== 'undefined') {
      const isNumber = Number.isFinite(parseInt(initial, 10));
      const value = initial;
      const valuePostfix = isNumber ? 'px' : '';

      return `${cssProperty}: ${value}${valuePostfix};`;
    }
  };
