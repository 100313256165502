import PropTypes from 'prop-types';
import React from 'react';
import { InputSizeEnum, InputStateEnum } from './enums';
import { StyledInput } from './styled-components';
import { INPUT_THEME_DEFAULT } from './themes';

export const Input = React.forwardRef(
  (
    { as, size = InputSizeEnum.NORMAL, themeDefinition = INPUT_THEME_DEFAULT, hasError, ...props },
    ref
  ) => {
    const state = hasError ? InputStateEnum.ERROR : InputStateEnum.IDLE;
    const stateTheme = themeDefinition[state];

    return <StyledInput ref={ref} as={as} size={size} theme={stateTheme} {...props} />;
  }
);

Input.propTypes = {
  as: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(InputSizeEnum)),
  themeDefinition: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.string,
};
