import { Link } from '~/src/components/Link';
import { CodePen } from "@uc-common/codepen";
import { Notification } from '~/src/components/Typography';
import { Code } from "@uc-common/code";
import * as React from 'react';
export default {
  Link,
  CodePen,
  Notification,
  Code,
  React
};