import { Link } from '~/src/components/Link';
import { Code } from "@uc-common/code";
import { colorsExample, colorsExample2 } from "~/src/learning/code-examples/code-examples";
import * as React from 'react';
export default {
  Link,
  Code,
  colorsExample,
  colorsExample2,
  React
};