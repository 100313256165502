import { css } from 'styled-components';
import { InputSelectSizeEnum } from '../enums';
import { inputSizeCss } from '../../input';

export const inputSelectSizeCss = css`
  ${inputSizeCss}
  ${({ size }) => {
    switch (size) {
      case InputSelectSizeEnum.EXTRA_SMALL:
        return css`
          min-height: 26px;
        `;
      case InputSelectSizeEnum.SMALL:
        return css`
          min-height: 34px;
        `;
      case InputSelectSizeEnum.LARGE:
        return css`
          min-height: 52px;
        `;
      case InputSelectSizeEnum.NORMAL:
        return css`
          min-height: 44px;
        `;
      default:
        // eslint-disable-next-line no-console
        console.warn(`InputSelect size "${size}" not implemented`);
    }
  }}
`;
