/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const React = require('react');
const { AuthContextProvider } = require('@uc-common/check-auth');
const { LeadFormContextProvider } = require('@uc-common/use-lead-form');
const Gtm = require('@uc-common/gtm').GTM;

exports.wrapRootElement = ({ element }) => (
  // eslint-disable-next-line react/jsx-filename-extension
  <AuthContextProvider endpoint={`${process.env.GATSBY_API_URL}/accounts/context/`}>
    <LeadFormContextProvider endpoint={`${process.env.GATSBY_API_URL}/apps/api/v0.1/lead_form/`}>
      {element}
    </LeadFormContextProvider>
    <Gtm containerID={process.env.GATSBY_GTM_CONTAINER_ID} />
  </AuthContextProvider>
);

exports.onClientEntry = async () => {
  // eslint-disable-next-line no-undef
  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }

  // eslint-disable-next-line no-undef
  if (typeof window.ResizeObserver === 'undefined') {
    const module = await import('@juggle/resize-observer');
    // eslint-disable-next-line no-undef
    window.ResizeObserver = module.ResizeObserver;
  }
};

exports.shouldUpdateScroll = ({
  routerProps: {
    location: { state },
  },
}) => {
  const { noScroll } = state || {};

  if (noScroll) {
    return false;
  }

  return true;
};
